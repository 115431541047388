import React, { useState, useEffect } from "react";
import { Tabs, Tab, Button, Modal, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import Select from "react-select";
import { urls } from "../URLs";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import SettingsSideBar from "./SettingsSideBar";

const options = [
  { value: "chocolate", label: "virtual_account.credited" },
  { value: "strawberry", label: "virtual_account.created" },
  { value: "vanilla", label: "virtual_account.closed" },
];

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "#FFF",
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    borderColor: state.isFocused ? "blue" : "green",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "green" : "blue",
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
};

const columns = [
  {
    dataField: "webhookUrl",
    text: "URL",
    formatter: (row, col) => {
      return (
        <div>
          <button
            className="btn btn-link btn-fw "
            type="button"
            keyField="id"
            onClick={() =>
              document
                .querySelector(".content-wrapper")
                .classList.toggle("sidebar-icon-only")
            }
          >
            {row}
          </button>
        </div>
      );
    },
  },
  {
    dataField: "webhookId",
    text: "ID",
  },

  {
    dataField: "userName",
    text: "User Name",
  },
  {
    dataField: "events",
    text: "Events",
  },
];

const defaultSorted = [
  {
    dataField: "id",
    order: "desc",
  },
];

const { SearchBar } = Search;

const whiteStyle = { background: "#FFF" };
const greyStyle = { background: "#e7eaf6" };
const rowStyle = (row, index) => {
  if (index % 2 == 0) {
    return greyStyle;
  } else {
    return whiteStyle;
  }
};

function Modals({ callback }) {
  const { createWebhook: createWebhookUrl } = urls;
  const [mdShow, setMdShow] = useState(false);
  const [secret, setSecret] = useState("");
  const [url, setUrl] = useState("");
  const [email, setEmail] = useState("");
  const [createVirtualLoading, setCreateVirtualLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  console.log(callback);
  const validate = () => {
    if (url === "") {
      toast.error("Please Enter URL");
      return false;
    }
    return true;
  };
  const createWebhook = async () => {
    if (!validate()) {
      return;
    }
    var myHeaders = new Headers();
    const accessToken = localStorage.getItem("accessToken");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-izsZt4LWb9mPQVlfmqKbp1OylMsqaQVgGThPtUXOIAjCy60oS0SFijCCv1BZAl0yGE34vcNJdgAcpe01xqmnMbeAbpYa1BtEVSG0gHBlFTgGcteFvMxYkI9MGsdKwXnbJsMkHN3d3aXC69jzZapD843TN8A3F9W5MJGfDVgdt3g3DNfnRm1LXUfAkS9H5JPiImHo3nYAHCXj7iCplOuHSyJcncydEhxCHUrS-EDCmReXPM-uRjUqX_ruI1D3eFQmcA3pktgVqvkaJCdDJ4UDTRjr71Xk1e3XffGMLXklemgeIzmoCDlm4Nzd0cwS9QzGjMNFxjloGZa41cI9KbCKxZyBXuAZWxT4UX2Clk54BZwD3pqoY-DFXfgcriuXrQqkSu2dDf-2LM5GOmjNE-zO0_hpf34ZPdUxfQfxub0qSlQIuBOFjsCGZ-GRNNcC4PRX3bagTkOU8DstH3y9AHv-C0l3SheD2aoyRajQuSVSPC8pN_JnCuJih9QZXk-TCmKZElEMjGRNHF7qr9nQQQlgukfRv_88a51XZL-PW3cptXGiM5ehM_qP85O_a7BnWuEEOdM9I9oE8PMWM3Kec7e9p1OqxtBZwZDHHXTffbyuf6wiu1z3a-5usDSZ06DLm5V0MlD26uNIdOa1u2C04hIUcPF"
    );

    var raw = JSON.stringify({
      webhookUrl: url,
      userId: 0,
      isActive: true,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(createWebhookUrl, requestOptions);
      if (res.ok) {
        const json = await res.text();
        const body = JSON.parse(json);
        console.log(body);
        toast.success("Webhook created successfuly");
        callback();
        setMdShow(false);
      } else {
        setMdShow(false);
      }
    } catch (error) {
      setMdShow(false);
    }
  };

  return (
    <div>
      <div className="text-center">
        <ToastContainer
          autoClose={3000}
          hideProgressBar
          draggableDirection="y"
        />
        <Button onClick={() => setMdShow(true)} className="btn-sm float-right">
          + Add New Webhhok
        </Button>
      </div>
      <Modal
        show={mdShow}
        onHide={() => setMdShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Webhook Setup</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{ padding: "0px", margin: "0px" }}
          className="account"
        >
          <div style={{ padding: "10px" }} className="col">
            <div className="input-group mb-2 mr-sm-2 mb-sm-0">
              <span style={{ padding: "10px", width: "8rem" }}>
                Webhook URL{" "}
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="HTTPS URL is recommended"
                value={url}
                onChange={(event) => setUrl(event.target.value)}
              />
            </div>
            {/* ifcs code */}
            <div className="input-group mb-2 mr-sm-2 mb-sm-0">
              <span style={{ padding: "10px", width: "8rem" }}> Secret</span>
              <input
                type="text"
                className="form-control"
                placeholder="secret"
                value={secret}
                disabled
                onChange={(event) => setSecret(event.target.value)}
              />
            </div>
            {/* mobile no */}
            <div className="input-group mb-2 mr-sm-2 mb-sm-0">
              <span style={{ padding: "10px", width: "8rem" }}>
                {" "}
                Alert Email{" "}
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="email"
                value={email}
                disabled
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            {/* pan no */}
            <div
              style={{ width: "100%" }}
              className="input-group mb-2 mr-sm-2 mb-sm-0"
            >
              <span style={{ padding: "10px", width: "8rem" }}>
                {" "}
                Active Events{" "}
              </span>
              <div style={{ width: "100%" }} v>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  styles={customStyles}
                  isMulti={true}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="fleex-wrap">
          <Button variant="light m-2" onClick={() => setMdShow(false)}>
            Cancel
          </Button>
          <Button variant="primary m-2" onClick={createWebhook}>
            Create Webhook
          </Button>
          {createVirtualLoading ? <Spinner animation="border" /> : null}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function Modal2({ callback, open, close, details }) {
  const { updateWebhook: updateWebhookUrl } = urls;

  const [mdShow, setMdShow] = useState(open);
  console.log(details);
  const [url, setUrl] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const validate = () => {
    if (url === "") {
      toast.error("Please Enter URL");
      return false;
    }
    return true;
  };
  const updateWebhook = async () => {
    if (!validate()) {
      return;
    }
    var myHeaders = new Headers();
    const accessToken = localStorage.getItem("accessToken");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-izBE1YV-OgoBC9lrxq8l9qSnO_7RS1terVR_xzrR6SNuRU-dws4AVfr3Tel33U5itsOEcIIT75NdDUZt_ipF66xDxE6_SK4aV5uMRwHsZ3uwel-6PTWqqPoKcyZVV_5DblDahRcEzrC6ySeIlEhwI_UdjBOqmF7cKFdfbqEHFNONyGhZE3L0NyX_NhpQ_MsyIrPBintutsLWYZlb8Vm_WDlJ9bk5hseyoPIFscRjU-BGVnyON8vkJF-2_kMqQF0YYZmU3tteRjmi1hKsY4CgPKURQSFkkNBQFzd3g_CVNviGkY5b0qX-nJ5rK2N-zohRwdMCgLgEVLr7HXEY-2rA2Epr8dfAvLfqA-k1NXxD99ga3CJvmDloKIczEsPFJ02aJ6oHUuPhjJdNKhXAVoIoKZlwSLfPelTMMSxf2MFuQTCeBD8vIH3XdAhjkuglBk_iCHCUdA5pvaHR29uxRffgp6kudoYBakEah-5Mv2kZ9z0KGjwUxPeVWzhoFgzC7M5aCAVRJUzaqVbqOeI3tUFYD7fIeMwSM0Ia1q_4_SdcQ2HrkKFJZ0q5DaFvWtjEy9fsdxyHSHTDvVjWEkHXjs8UqOXWc4JY80rPT8eYP4rmZ4b69F1uL08UmW9bl7JVehX-8mxLxDHdrLSvNPiSKe7Bifb"
    );

    var raw = JSON.stringify({
      webhookId: details.webhookId,
      webhookUrl: url,
      userId: 0,
      isActive: true,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(updateWebhookUrl, requestOptions);
      if (res.ok) {
        const json = await res.text();
        const body = JSON.parse(json);
        console.log(body);
        toast.success("Webhook updated successfuly");
        callback();
        close();
      } else {
        close();
      }
    } catch (error) {
      close();
    }
  };
  useEffect(() => {
    setMdShow(open);
  }, []);

  return (
    <div>
      <div className="text-center">
        <ToastContainer
          autoClose={3000}
          hideProgressBar
          draggableDirection="y"
        />
      </div>
      <Modal
        show={open}
        onHide={close}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Webhook Update</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{ padding: "0px", margin: "0px" }}
          className="account"
        >
          <div style={{ padding: "10px" }} className="col">
            <div className="input-group mb-2 mr-sm-2 mb-sm-0">
              <span style={{ padding: "10px", width: "8rem" }}>
                Webhook URL{" "}
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="HTTPS URL is recommended"
                value={url}
                onChange={(event) => setUrl(event.target.value)}
              />
            </div>
            {/* ifcs code */}
            <div className="input-group mb-2 mr-sm-2 mb-sm-0">
              <span style={{ padding: "10px", width: "8rem" }}> Secret</span>
              <input
                type="text"
                className="form-control"
                placeholder="secret"
                disabled
              />
            </div>
            {/* mobile no */}
            <div className="input-group mb-2 mr-sm-2 mb-sm-0">
              <span style={{ padding: "10px", width: "8rem" }}>
                {" "}
                Alert Email{" "}
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="email"
                disabled
              />
            </div>
            {/* pan no */}
            <div
              style={{ width: "100%" }}
              className="input-group mb-2 mr-sm-2 mb-sm-0"
            >
              <span style={{ padding: "10px", width: "8rem" }}>
                {" "}
                Active Events{" "}
              </span>
              <div style={{ width: "100%" }} v>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  styles={customStyles}
                  isMulti={true}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="fleex-wrap">
          <Button variant="light m-2" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary m-2" onClick={updateWebhook}>
            Update Webhook
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const Settings = () => {
  const { getWebhooks: getWebhooksUrl } = urls;
  const [webHooks, setWebHooks] = useState([]);
  const [details, setDetails] = useState({});
  const [modal2, setModal2] = useState(false);
  console.log(webHooks);
  const refresh = () => {
    getAllWebhooks();
  };
  function setsidebarwidth() {
    document
      .querySelector(".content-wrapper")
      .classList.toggle("sidebar-icon-only");
  }
  const getAllWebhooks = async () => {
    var myHeaders = new Headers();
    const accessToken = localStorage.getItem("accessToken");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-izxMYOW6daLj6LChaGSQV9gW_AInGpxyc3sE4UIe866ScoKde8truE4S2cWYXrAGQWIjz-kfksAO-reJ78rtp73BQRstzO_Vx34xNdXkPSw2hYOPYjDGf71MQ9-OCs-Y-H8u_pB1IOFXH3cw0UZ5ppusfwqmTg62yWaI0TCPvM6guD8cm6Q4e9eGRvRX8PhTTqP7VmsX358zg8YY-By8ZkOaIcIHcnPtzM0P-TurMatC0LJW_gddw1A8OJV42zN8opziBddH0ul6KFcfeAqk8RLjC5Cc_c3q312YBfH30mALOImvnU5BTAfR3jKg62BccJCBCyJbh0u3PbfPaETPLv3jLOUSzyrqZ1erPXw9tFvUxafjqfSXub18UvdU8_pbL7xEgfkFIs6rBuwDbpzAD1VRpW8_FcjhmFaOF6GrQO1wnR7uH65bS-oj8UCSwLDinaM0F4HgEHYGkjwSuKbWw0SiVC3YXcuMeQQa8Un3JgKB_8gIb-cY-tYe8I8NFqIU-lc1YKWa-p5ZSaRbgv1qBMdZNFF2cMBelSvXY7Di1sG2dmYETzLEkRCwzgPKtXg6GFUME1rZEX8D7vYpfrNvqocBX023K2OGSTNkGD0ACIoshs3Veb_YjOfTn72D8xTT83BLfe_ejs6g1DCLcreLObh"
    );

    var raw = JSON.stringify({
      pageIndex: 1,
      pageSize: 1000,
      userId: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(getWebhooksUrl, requestOptions);
      if (res.ok) {
        const json = await res.text();
        const body = JSON.parse(json);
        setWebHooks(body.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  function close() {
    setModal2(false);
  }
  useEffect(() => {
    getAllWebhooks();
  }, []);
  console.log(webHooks);
  return (
    <div>
      <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img src={require("../../assets/images/logo-mini.svg")} alt="logo" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <SettingsSideBar details={details} callback={setModal2} />
      </div>
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <Modals callback={refresh} />
            <Modal2
              callback={refresh}
              open={modal2}
              close={close}
              details={details}
            />
            <Tabs defaultActiveKey="Webhooks" id="uncontrolled-tab-example">
              <Tab
                eventKey="Configurations"
                title="Configurations"
                className="test-tab"
              >
                <h1>Configurations</h1>
              </Tab>

              <Tab eventKey="Webhooks" title="Webhooks">
                <div>
                  <div className="row">
                    <div className="col-12">
                      <div
                        style={{ padding: "0px", margin: "0px" }}
                        className="card"
                      >
                        <div
                          style={{ padding: "0px", margin: "0px" }}
                          className="card-body"
                        >
                          <div className="row">
                            <div className="col-12">
                              {webHooks.length == 0 ? (
                                <EmptyArrayHandler />
                              ) : (
                                <ToolkitProvider
                                  keyField="id"
                                  bootstrap4
                                  data={webHooks}
                                  columns={columns}
                                  search
                                >
                                  {(props, i) => (
                                    <BootstrapTable
                                      rowStyle={rowStyle}
                                      rowEvents={{
                                        onClick: (e, row, rowIndex) => {
                                          console.log(row);
                                          setDetails(row);
                                        },
                                      }}
                                      defaultSorted={defaultSorted}
                                      pagination={paginationFactory()}
                                      {...props.baseProps}
                                      wrapperClasses="table-responsive"
                                    />
                                  )}
                                </ToolkitProvider>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
const EmptyArrayHandler = () => {
  return (
    <div className="row">
      <div className="col-12">
        <span style={{ fontSize: "1.4rem" }}>No records Found</span>
      </div>
    </div>
  );
};
export default Settings;
