import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Tabs,
  Tab,
  Accordion,
  Card,
} from "react-bootstrap";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import Select from "react-select";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import { GlobalContext } from "../Context/GlobalState";
import { AiOutlineClose } from "react-icons/ai";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
export const Oboardform = ({ Getonboarddata }) => {
  const [formData, setFormData] = useState({
    companyName: "",
    ownerName: "",
    mobile: "",
    regNumber: "",
    gst: "",
    pan: "",
    domain: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear specific field error when user starts typing
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const updatedDomain = formData.domain.startsWith("https://")
        ? formData.domain
        : `https://${formData.domain}`;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        company_name: formData.companyName,
        domain: updatedDomain,
        reg: formData.regNumber,
        gst: formData.gst,
        company_pan: formData.pan,
        founder: formData.ownerName,
        mobile: formData.mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/OnboardCompany`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
        setFormData({
          companyName: "",
          ownerName: "",
          mobile: "",
          regNumber: "",
          gst: "",
          pan: "",
          domain: "",
        });
        Getonboarddata();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (formData.companyName.trim() === "") {
      errors.companyName = "*Please enter company name";
      valid = false;
    }

    if (formData.ownerName.trim() === "") {
      errors.ownerName = "*Please enter owner name";
      valid = false;
    }

    if (formData.mobile.trim() === "") {
      errors.mobile = "*Please enter mobile number";
      valid = false;
    }

    if (formData.regNumber.trim() === "") {
      errors.regNumber = "*Please enter registration number";
      valid = false;
    }

    if (formData.gst.trim() === "") {
      errors.gst = "*Please enter GST";
      valid = false;
    }

    if (formData.pan.trim() === "") {
      errors.pan = "*Please enter PAN";
      valid = false;
    }

    if (formData.domain.trim() === "") {
      errors.domain = "*Please enter domain";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmitWithValidation = (e) => {
    e.preventDefault();
    if (validate()) {
      handleSubmit();
    }
  };

  return (
    <Container>
      <h3 className="mt-4 mb-4 text-center">Company Onboarding Form</h3>
      <Form onSubmit={handleSubmitWithValidation}>
        <Row className="m-0 p-0">
          <Col md={4}>
            <Form.Group controlId="formCompanyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                placeholder="Enter company name"
                isInvalid={!!errors.companyName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.companyName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="formOwnerName">
              <Form.Label>Owner Name</Form.Label>
              <Form.Control
                type="text"
                name="ownerName"
                value={formData.ownerName}
                onChange={handleChange}
                placeholder="Enter owner name"
                isInvalid={!!errors.ownerName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.ownerName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="formMobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                placeholder="Enter mobile number"
                isInvalid={!!errors.mobile}
              />
              <Form.Control.Feedback type="invalid">
                {errors.mobile}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col md={4}>
            <Form.Group controlId="formRegNumber">
              <Form.Label>Registration Number</Form.Label>
              <Form.Control
                type="text"
                name="regNumber"
                value={formData.regNumber}
                onChange={handleChange}
                placeholder="Enter registration number"
                isInvalid={!!errors.regNumber}
              />
              <Form.Control.Feedback type="invalid">
                {errors.regNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="formGst">
              <Form.Label>GST</Form.Label>
              <Form.Control
                type="text"
                name="gst"
                value={formData.gst}
                onChange={handleChange}
                placeholder="Enter GST"
                isInvalid={!!errors.gst}
              />
              <Form.Control.Feedback type="invalid">
                {errors.gst}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="formPan">
              <Form.Label>PAN</Form.Label>
              <Form.Control
                type="text"
                name="pan"
                value={formData.pan}
                onChange={handleChange}
                placeholder="Enter PAN"
                isInvalid={!!errors.pan}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pan}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col md={4}>
            <Form.Group controlId="formDomain">
              <Form.Label>Domain</Form.Label>
              <Form.Control
                type="text"
                name="domain"
                value={formData.domain}
                onChange={handleChange}
                placeholder="Enter domain"
                isInvalid={!!errors.domain}
              />
              <Form.Control.Feedback type="invalid">
                {errors.domain}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={4} className="d-flex align-items-center">
            <Button variant="primary" size="sm" type="submit" className="mt-2">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
export const Internallinksform = () => {
  const [formData, setFormData] = useState([{ href: "", pagename: "" }]);
  const id = sessionStorage.getItem("selectedURLId");
  const url = sessionStorage.getItem("selectedURLLabel");
  const { setTransactionLoaderState } = useContext(GlobalContext);

  const handleInputChange = (index, event) => {
    const values = [...formData];
    values[index][event.target.name] = event.target.value;
    setFormData(values);
  };

  const handleAddField = () => {
    setFormData([...formData, { href: "", pagename: "" }]);
  };

  const handleRemoveField = (index) => {
    const values = [...formData];
    values.splice(index, 1);
    setFormData(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requests = formData.map((data) => ({
      webid: id,
      href: data.href,
      name: data.pagename,
    }));

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requests }),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddInternalLinks`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        Getinternallinksdata(); // Refresh data after successful save
      } else {
        swal("Error", data.result, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const Getinternallinksdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllInternalLinks`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        setFormData(
          data.data.map((item) => ({
            href: item.href || "",
            pagename: item.name || "",
          }))
        );
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    Getinternallinksdata();
  }, []);
  return (
    <Container className="m-2">
      <h5 className="mt-4 mb-4 text-center">
        Manage Internal Links <u>{url}</u>
      </h5>
      <form className="" onSubmit={handleSubmit}>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>Href</th>
              <th>Page Name</th>
              <th
                className="d-flex justify-content-center"
                style={{ border: "none" }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {formData.map((data, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    name="href"
                    value={data.href}
                    onChange={(event) => handleInputChange(index, event)}
                    placeholder="Enter Href"
                    className="form-control"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    name="pagename"
                    value={data.pagename}
                    onChange={(event) => handleInputChange(index, event)}
                    placeholder="Enter Page Name"
                    className="form-control"
                  />
                </td>
                <td
                  className="d-flex"
                  style={{ border: "none", justifyContent: "center" }}
                >
                  {formData.length > 1 && (
                    <IoIosRemoveCircle
                      style={{
                        fontSize: "1.5rem",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRemoveField(index)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex mt-2">
          <IoIosAddCircle
            className="mr-2"
            style={{ color: "green", fontSize: "1.5rem", cursor: "pointer" }}
            onClick={handleAddField}
          />
          <Button size="sm" type="submit" variant="primary">
            Save
          </Button>
        </div>
      </form>
    </Container>
  );
};
export const Keyword = () => {
  const id = sessionStorage.getItem("selectedURLId");
  const pageid = JSON.parse(sessionStorage.getItem("pageid"));

  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const [keyword, setKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);

  const handleAddKeyword = (kw = keyword) => {
    if (kw) {
      const newKeywords = kw
        .split(",")
        .map((kw) => kw.trim())
        .filter(
          (kw) => kw && !keywords.some((existing) => existing.keyword === kw)
        );
      setKeywords([
        ...keywords,
        ...newKeywords.map((kw) => ({
          keyword: kw,
          id: Date.now() + Math.random(),
        })),
      ]);
      setKeyword("");
      setSuggestedKeywords([]);
    }
  };

  const handleRemoveKeyword = async (keyid) => {
    console.log(keyid);
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          webid: id,
          pageid: pageid.id,
          keyid: keyid,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/DeleteKeywords`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.data[0].message, "success");
        setKeywords(keywords.filter((kw) => kw.id !== keyid));
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleSubmitKeywords = async () => {
    const requests = keywords.map((kw) => ({
      webid: id,
      pageid: pageid.id,
      keyword: kw.keyword,
    }));

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requests }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddKeyword`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.result, "error");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const Getkeyworddata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: id, pageid: pageid.id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllKeywords`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setKeywords(
          data.data.map((item) => ({
            keyword: item.keyword,
            id: item.id,
          }))
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const GetSuggestedKeywordData = async (input) => {
    if (input.trim() === "") {
      setSuggestedKeywords([]);
      return;
    }
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userid: user.user_id,
          url: "https://www.billpunch.com/",
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllKeywordsSuggestion`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const allKeywords = data.data[0].keywords.split(",");
        setSuggestedKeywords(allKeywords);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getkeyworddata();
  }, []);

  return (
    <Card className="p-0">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="4"
        className="m-0 p-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: ".2rem",
          border: "1px solid rgb(170, 215, 189)",
          background: "rgb(236, 246, 240)",
        }}
      >
        <div>Keywords</div>
        <div>
          <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="4">
        <Card.Body
          className="p-2"
          style={{
            background: "#FEFAF6",
            border: "1px solid rgb(170, 215, 189)",
          }}
        >
          <Form inline className="m-2">
            <Form.Control
              type="text"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                GetSuggestedKeywordData(e.target.value);
              }}
              placeholder="Enter keywords separated by commas"
              className="mr-2"
              style={{ width: "70%" }}
            />
            <IoCheckmarkDoneCircle
              style={{
                color: "green",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
              onClick={() => handleAddKeyword()}
            />
          </Form>
          {/* Render suggested keywords below the input field */}
          {suggestedKeywords.length > 0 && (
            <div className="suggestion-dropdown" style={{ maxHeight: "150px", overflowY: "auto", border: "1px solid #ddd", marginTop: "10px" }}>
              {suggestedKeywords.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  style={{
                    cursor: "pointer",
                    padding: "5px",
                    borderBottom: "1px solid #ddd",
                  }}
                  onClick={() => handleAddKeyword(suggestion)}
                >
                  {suggestion}
                </div>
              ))}
            </div>
          )}
          <Tab.Container id="keyword-tabs" defaultActiveKey="keywords">
            <Tab.Content>
              <Tab.Pane eventKey="keywords">
                <div className="d-flex flex-wrap">
                  {keywords.length > 0 ? (
                    <>
                      {keywords.map((kw) => (
                        <div
                          key={kw.id}
                          className="d-flex align-items-center m-1 p-2 border rounded bg-light"
                          style={{
                            position: "relative",
                            margin: "5px",
                            padding: "5px 10px",
                          }}
                        >
                          {kw.keyword} &nbsp; &nbsp;
                          <AiOutlineClose
                            style={{
                              position: "absolute",
                              right: "5px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            onClick={() => handleRemoveKeyword(kw.id)} // Use kw.id to remove
                          />
                        </div>
                      ))}
                      <Button
                        type="button" // Use type="button" to prevent form submission
                        size="sm"
                        variant="primary"
                        className="ml-2 mt-2"
                        style={{ height: "2rem" }}
                        onClick={handleSubmitKeywords}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <div className="text-center mt-4">No keywords found</div>
                  )}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};


export const Header = () => {
  const id = sessionStorage.getItem("selectedURLId");
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const pageid = JSON.parse(sessionStorage.getItem("pageid"));

  const initialHeadersState = {
    H1: [{ text: "" }],
    H2: [{ text: "" }],
    H3: [{ text: "" }],
    H4: [{ text: "" }],
    H5: [{ text: "" }],
    H6: [{ text: "" }],
  };

  const [headers, setHeaders] = useState(initialHeadersState);
  const [tabKey, setTabKey] = useState("H1");
  const [fetchedData, setFetchedData] = useState([]);

  const handleHeaderChange = (type, index, event) => {
    const newHeaders = { ...headers };
    newHeaders[type][index].text = event.target.value;
    setHeaders(newHeaders);
  };

  const addHeader = (type) => {
    setHeaders({ ...headers, [type]: [...headers[type], { text: "" }] });
  };

  const removeHeader = (type, index) => {
    const newHeaders = { ...headers };
    newHeaders[type] = newHeaders[type].filter((_, i) => i !== index);
    setHeaders(newHeaders);
  };

  const handleSubmitheaders = async (e) => {
    e.preventDefault();

    const requests = [];

    Object.keys(headers).forEach((type) => {
      headers[type].forEach((header) => {
        if (header.text.trim() !== "") {
          requests.push({
            webid: id,
            pageid: pageid.id,
            header_type: type,
            header_content: header.text,
          });
        }
      });
    });

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requests }),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddHeaders`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
        Getheaderdata();
      } else {
        swal("Error", data.result, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
      swal(
        "Error",
        "An unexpected error occurred. Please try again later.",
        "error"
      );
    }
  };

  const Getheaderdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: id, pageid: pageid.id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllHeaders`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setFetchedData(data.data);
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getheaderdata();
  }, []);

  const handleRemoveHeader = async (headerId) => {
    if (!headerId) {
      console.error("Header ID is missing.");
      return;
    }

    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          webid: id,
          pageid: pageid.id,
          headerid: headerId,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/DeleteHeaders`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.result[0].message, "success");

        Getheaderdata();
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  return (
    <Card className="p-0">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="2"
        className="m-0 p-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: ".2rem",
          border: "1px solid rgb(170, 215, 189)",
          background: "rgb(236, 246, 240)",
        }}
      >
        <div>Header</div>
        <div>
          <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="2">
        <Card.Body
          className="p-2"
          style={{
            background: "#FEFAF6",
            border: "1px solid rgb(170, 215, 189)",
          }}
        >
          <Tabs
            id="header-tabs"
            activeKey={tabKey}
            onSelect={(k) => setTabKey(k)}
            className="m-2"
          >
            {Object.keys(headers).map((type) => (
              <Tab
                key={type}
                eventKey={type}
                title={
                  <span style={{ fontSize: "1rem", padding: "0 10px" }}>
                    {type}
                  </span>
                }
              >
                <div className="p-2">
                  {/* Form for adding headers */}
                  <Form onSubmit={(e) => handleSubmitheaders(e)}>
                    {headers[type].map((header, index) => (
                      <div key={index} className="row m-0 p-0 mb-2">
                        <Form.Group
                          className="col-10 m-0 p-0"
                          controlId={`form${type}${index}`}
                        >
                          <Form.Control
                            type="text"
                            placeholder={`Enter ${type} text`}
                            value={header.text}
                            onChange={(e) => handleHeaderChange(type, index, e)}
                          />
                        </Form.Group>
                        <div className="col-2 d-flex align-items-center justify-content-end">
                          <IoIosRemoveCircle
                            onClick={() => removeHeader(type, index)}
                            style={{ color: "red", fontSize: "2rem" }}
                          />
                        </div>
                      </div>
                    ))}
                    <IoIosAddCircle
                      onClick={() => addHeader(type)}
                      style={{ color: "green", fontSize: "2rem" }}
                    />
                    <Button size="sm" variant="primary" type="submit">
                      Save
                    </Button>
                  </Form>

                  {/* Display fetched data */}
                  <div className="mt-4">
                    <h6>Existing {type} Headers</h6>
                    <div
                      style={{
                        border: "1px solid #ddd",
                        padding: "1rem",
                        borderRadius: ".2rem",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      {fetchedData.filter((item) => item.header_type === type)
                        .length === 0 ? (
                        <p>No headers available</p>
                      ) : (
                        <ul>
                          {fetchedData
                            .filter((item) => item.header_type === type)
                            .map((item, index) => (
                              <li key={index}>
                                {item.header_content} &nbsp; &nbsp;
                                <AiOutlineClose
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                  onClick={() => handleRemoveHeader(item.id)}
                                />
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
            ))}
          </Tabs>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export const Ogsetting = () => {
  const id = sessionStorage.getItem("selectedURLId");

  const pageid = JSON.parse(sessionStorage.getItem("pageid"));

  const [ogSetting, setOgSetting] = useState({
    ogTitle: "",
    ogDescription: "",
    ogUrl: "",
    ogImage: "",
    ogType: "",
    ogSiteName: "",
    ogLocale: "",
    ogAudio: "",
    ogVideo: "",
    ogVideoSecureUrl: "",
    ogVideoType: "",
    ogVideoWidth: "",
    ogVideoHeight: "",
    ogDeterminer: "",
    twitterCard: "",
    twitterSite: "",
    twitterTitle: "",
    twitterDescription: "",
    twitterImage: "",
    twitterUrl: "",
    twitterCreator: "",
    ogArticleAuthor: "",
    ogArticlePublishedTime: "",
    ogArticleModifiedTime: "",
    fbAppId: "",
  });
  const { setTransactionLoaderState } = useContext(GlobalContext);

  const handleOGSubmit = async (e) => {
    e.preventDefault();

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          webId: id,
          pageId: pageid.id,
          ogTitle: ogSetting.ogTitle,
          ogDesc: ogSetting.ogDescription,
          ogImage: ogSetting.ogImage,
          ogUrl: ogSetting.ogUrl,
          ogType: ogSetting.ogType,
          ogLocale: ogSetting.ogLocale,
          ogSitename: ogSetting.ogSiteName,
          ogAudio: ogSetting.ogAudio,
          ogVideo: ogSetting.ogVideo,
          ogVideoSecureUrl: ogSetting.ogVideoSecureUrl,
          ogVideoType: ogSetting.ogVideoType,
          ogVideoWidth: ogSetting.ogVideoWidth,
          ogVideoHeight: ogSetting.ogVideoHeight,
          ogDeterminer: ogSetting.ogDeterminer,
          twittercard: ogSetting.twitterCard,
          twitterSite: ogSetting.twitterSite,
          twitterTitle: ogSetting.twitterTitle,
          twitterDesc: ogSetting.twitterDescription,
          twitterImage: ogSetting.twitterImage,
          twitterUrl: ogSetting.twitterUrl,
          twitterCreator: ogSetting.twitterCreator,
          fbAppId: ogSetting.fbAppId,
          fbOgArticleAuthor: ogSetting.ogArticleAuthor,
          fbOgArticlePublishedTime: ogSetting.ogArticlePublishedTime,
          fbOgArticleModifiedTime: ogSetting.ogArticleModifiedTime,
        }),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddOgSettingsSeo`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.result, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOgSetting((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const Getogsettingdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: id, pageid: pageid.id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllOgSettings`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        const fetchedData = data.data[0];
        setOgSetting({
          ogTitle: fetchedData.og_title || "",
          ogDescription: fetchedData.og_desc || "",
          ogUrl: fetchedData.og_url || "",
          ogImage: fetchedData.og_image || "",
          ogType: fetchedData.og_type || "",
          ogSiteName: fetchedData.og_sitename || "",
          ogLocale: fetchedData.og_locale || "",
          ogAudio: fetchedData.og_audio || "",
          ogVideo: fetchedData.og_video || "",
          ogVideoSecureUrl: fetchedData.og_video_secure_url || "",
          ogVideoType: fetchedData.og_video_type || "",
          ogVideoWidth: fetchedData.og_video_width || "",
          ogVideoHeight: fetchedData.og_video_height || "",
          ogDeterminer: fetchedData.og_determiner || "",
          twitterCard: fetchedData.twitter_card || "",
          twitterSite: fetchedData.twitter_site || "",
          twitterTitle: fetchedData.twitter_title || "",
          twitterDescription: fetchedData.twitter_desc || "",
          twitterImage: fetchedData.twitter_image || "",
          twitterUrl: fetchedData.twitter_url || "",
          twitterCreator: fetchedData.twitter_creator || "",
          ogArticleAuthor: fetchedData.fb_og_article_author || "",
          ogArticlePublishedTime:
            fetchedData.fb_og_article_published_time || "",
          ogArticleModifiedTime: fetchedData.fb_og_article_modified_time || "",
          fbAppId: fetchedData.fb_app_id || "",
        });
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getogsettingdata();
  }, []);

  return (
    <Card className="p-0">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="1"
        className="m-0 p-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: ".2rem",
          border: "1px solid rgb(170, 215, 189)",
          background: "rgb(236, 246, 240)",
        }}
      >
        <div>OG Setting</div>
        <div>
          <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body
          className="p-2"
          style={{
            background: "#FEFAF6",
            border: "1px solid rgb(170, 215, 189)",
          }}
        >
          <Form className="p-2 mt-2" onSubmit={handleOGSubmit}>
            <Form.Group controlId="formOgTitle">
              <Form.Label>OG Title</Form.Label>
              <Form.Control
                type="text"
                name="ogTitle"
                placeholder="Enter OG title"
                value={ogSetting.ogTitle}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgDescription">
              <Form.Label>OG Description</Form.Label>
              <Form.Control
                type="text"
                name="ogDescription"
                placeholder="Enter OG description"
                value={ogSetting.ogDescription}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgUrl">
              <Form.Label>OG URL</Form.Label>
              <Form.Control
                type="text"
                name="ogUrl"
                placeholder="Enter OG URL"
                value={ogSetting.ogUrl}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgImage">
              <Form.Label>OG Image</Form.Label>
              <Form.Control
                type="text"
                name="ogImage"
                placeholder="Enter OG image URL"
                value={ogSetting.ogImage}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgType">
              <Form.Label>OG Type</Form.Label>
              <Form.Control
                type="text"
                name="ogType"
                placeholder="Enter OG type"
                value={ogSetting.ogType}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgSiteName">
              <Form.Label>OG Site Name</Form.Label>
              <Form.Control
                type="text"
                name="ogSiteName"
                placeholder="Enter OG site name"
                value={ogSetting.ogSiteName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgLocale">
              <Form.Label>OG Locale</Form.Label>
              <Form.Control
                type="text"
                name="ogLocale"
                placeholder="Enter OG locale"
                value={ogSetting.ogLocale}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgAudio">
              <Form.Label>OG Audio</Form.Label>
              <Form.Control
                type="text"
                name="ogAudio"
                placeholder="Enter OG audio URL"
                value={ogSetting.ogAudio}
                onChange={handleChange}
              />
            </Form.Group>
            <h6>Video Setting</h6>
            <Form.Group controlId="formOgVideo">
              <Form.Label>OG Video</Form.Label>
              <Form.Control
                type="text"
                name="ogVideo"
                placeholder="Enter OG video URL"
                value={ogSetting.ogVideo}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgVideoSecureUrl">
              <Form.Label>OG Video Secure URL</Form.Label>
              <Form.Control
                type="text"
                name="ogVideoSecureUrl"
                placeholder="Enter OG video secure URL"
                value={ogSetting.ogVideoSecureUrl}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgVideoType">
              <Form.Label>OG Video Type</Form.Label>
              <Form.Control
                type="text"
                name="ogVideoType"
                placeholder="Enter OG video type"
                value={ogSetting.ogVideoType}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgVideoWidth">
              <Form.Label>OG Video Width</Form.Label>
              <Form.Control
                type="text"
                name="ogVideoWidth"
                placeholder="Enter OG video width"
                value={ogSetting.ogVideoWidth}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgVideoHeight">
              <Form.Label>OG Video Height</Form.Label>
              <Form.Control
                type="text"
                name="ogVideoHeight"
                placeholder="Enter OG video height"
                value={ogSetting.ogVideoHeight}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgDeterminer">
              <Form.Label>OG Determiner</Form.Label>
              <Form.Control
                type="text"
                name="ogDeterminer"
                placeholder="Enter OG determiner"
                value={ogSetting.ogDeterminer}
                onChange={handleChange}
              />
            </Form.Group>
            <h6>Twitter Setting</h6>
            <Form.Group controlId="formTwitterCard">
              <Form.Label>Twitter Card</Form.Label>
              <Form.Control
                type="text"
                name="twitterCard"
                placeholder="Enter Twitter card"
                value={ogSetting.twitterCard}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTwitterSite">
              <Form.Label>Twitter Site</Form.Label>
              <Form.Control
                type="text"
                name="twitterSite"
                placeholder="Enter Twitter site"
                value={ogSetting.twitterSite}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTwitterTitle">
              <Form.Label>Twitter Title</Form.Label>
              <Form.Control
                type="text"
                name="twitterTitle"
                placeholder="Enter Twitter title"
                value={ogSetting.twitterTitle}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTwitterDescription">
              <Form.Label>Twitter Description</Form.Label>
              <Form.Control
                type="text"
                name="twitterDescription"
                placeholder="Enter Twitter description"
                value={ogSetting.twitterDescription}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTwitterImage">
              <Form.Label>Twitter Image</Form.Label>
              <Form.Control
                type="text"
                name="twitterImage"
                placeholder="Enter Twitter image URL"
                value={ogSetting.twitterImage}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTwitterUrl">
              <Form.Label>Twitter URL</Form.Label>
              <Form.Control
                type="text"
                name="twitterUrl"
                placeholder="Enter Twitter URL"
                value={ogSetting.twitterUrl}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTwitterCreator">
              <Form.Label>Twitter Creator</Form.Label>
              <Form.Control
                type="text"
                name="twitterCreator"
                placeholder="Enter Twitter creator handle"
                value={ogSetting.twitterCreator}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="fbAppId">
              <Form.Label>Fb App Id</Form.Label>
              <Form.Control
                type="text"
                name="fbAppId"
                placeholder="Enter fbAppId"
                value={ogSetting.fbAppId}
                onChange={handleChange}
              />
            </Form.Group>
            <h6>Facebook Setting</h6>
            <Form.Group controlId="formOgArticleAuthor">
              <Form.Label>OG Article Author</Form.Label>
              <Form.Control
                type="text"
                name="ogArticleAuthor"
                placeholder="Enter OG article author"
                value={ogSetting.ogArticleAuthor}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgArticlePublishedTime">
              <Form.Label>OG Article Published Time</Form.Label>
              <Form.Control
                type="text"
                name="ogArticlePublishedTime"
                placeholder="Enter OG article published time"
                value={ogSetting.ogArticlePublishedTime}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formOgArticleModifiedTime">
              <Form.Label>OG Article Modified Time</Form.Label>
              <Form.Control
                type="text"
                name="ogArticleModifiedTime"
                placeholder="Enter OG article modified time"
                value={ogSetting.ogArticleModifiedTime}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="primary" size="sm" type="submit">
              Save
            </Button>
          </Form>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export const Paragraph = () => {
  const id = sessionStorage.getItem("selectedURLId");
  const pageid = JSON.parse(sessionStorage.getItem("pageid"));
  const { setTransactionLoaderState } = useContext(GlobalContext);

  const initialParagraphs = [{ text: "" }];
  const [paragraphs, setParagraphs] = useState(initialParagraphs);
  const [fetchedData, setFetchedData] = useState([]);

  const handleAccordionClick = (section) => {};

  const handleParagraphChange = (index, event) => {
    const newParagraphs = [...paragraphs];
    newParagraphs[index].text = event.target.value;
    setParagraphs(newParagraphs);
  };

  const addParagraph = () => {
    setParagraphs([...paragraphs, { text: "" }]);
  };

  const removeParagraph = (index) => {
    const newParagraphs = paragraphs.filter((_, i) => i !== index);
    setParagraphs(newParagraphs);
  };

  const handleSubmitpara = async (e) => {
    e.preventDefault();

    const requests = paragraphs.map((paragraph, index) => ({
      webid: id,
      page_id: pageid.id,
      para_type: `P${index + 1}`,
      para_content: paragraph.text,
    }));

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requests }),
      };

      console.log("Sending data to the server:", requests);
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddParagraph`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.msg, "success");
        Getparadata();
        setParagraphs(initialParagraphs); // Reset paragraphs state to clear the form fields
      } else {
        swal("Error", data.msg, "error");
      }
      console.log("Form submission completed:", data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const Getparadata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: id, pageid: pageid.id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllParagraphs`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setFetchedData(data.data);
        console.log("Fetched data:", data.data); // Log the fetched data to ensure para_id is present
      } else {
        console.error("Error fetching paragraphs:", data.msg);
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleRemoveFetchedData = async (paraId) => {
    if (!paraId) {
      console.error("Paragraph ID is missing.");
      return;
    }

    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          webid: id,
          pageid: pageid.id,
          paraid: paraId,
        }),
      };

      console.log("Sending delete request:", {
        webid: id,
        pageid: pageid.id,
        para_id: paraId,
      });
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/DeleteParagraphs`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.data[0].message, "success");
        setFetchedData(fetchedData.filter((item) => item.id !== paraId));
      } else {
        swal("Error", data.msg, "error");
      }
      console.log("Delete request completed:", data);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getparadata();
  }, []);

  return (
    <Card className="p-0">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="3"
        onClick={() => handleAccordionClick("Paragraphs")}
        className="m-0 p-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: ".2rem",
          border: "1px solid rgb(170, 215, 189)",
          background: "rgb(236, 246, 240)",
        }}
      >
        <div>Paragraphs</div>
        <div>
          <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="3">
        <Card.Body
          className="p-2"
          style={{
            background: "#FEFAF6",
            border: "1px solid rgb(170, 215, 189)",
          }}
        >
          <Form className="p-2 mt-2" onSubmit={handleSubmitpara}>
            <h6>Paragraphs</h6>
            {paragraphs.map((paragraph, index) => (
              <div key={index} className="row m-0 p-0 mb-2">
                <Form.Group
                  className="col-10 m-0 p-0"
                  controlId={`formParagraph${index}`}
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter paragraph text"
                    value={paragraph.text}
                    onChange={(e) => handleParagraphChange(index, e)}
                  />
                </Form.Group>
                <div className="col-2 d-flex align-items-center justify-content-end">
                  <IoIosRemoveCircle
                    onClick={() => removeParagraph(index)}
                    style={{ color: "red", fontSize: "2rem" }}
                  />
                </div>
              </div>
            ))}
            <IoIosAddCircle
              className="mb-3"
              onClick={addParagraph}
              style={{ color: "green", fontSize: "2rem" }}
            />
            <br />
            <Button size="sm" variant="primary" type="submit">
              Save
            </Button>
          </Form>
          <div className="mt-3">
            {fetchedData.map((item) => (
              <div
                key={item.id} // Assuming `id` is the unique identifier
                className="d-flex align-items-center m-1 p-2 border rounded bg-light"
                style={{
                  position: "relative",
                  margin: "5px",
                  padding: "5px 10px",
                }}
              >
                {item.para_content} &nbsp; &nbsp;
                <AiOutlineClose
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRemoveFetchedData(item.id)}
                />
              </div>
            ))}
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export const Basicsetting = () => {
  const id = sessionStorage.getItem("selectedURLId");
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const pageid = JSON.parse(sessionStorage.getItem("pageid"));

  const [basicSetting, setBasicSetting] = useState({
    title: "",
    metaDescription: "",
    canonicalLink: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          web_id: id,
          page_id: pageid.id,
          page_name: pageid.url_pages,
          tittle: basicSetting.title,
          description: basicSetting.metaDescription,
          canonical: basicSetting.canonicalLink,
        }),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddBasicSettingsSeo`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.msg, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleBasicSettingChange = (e) => {
    const { id, value } = e.target;
    setBasicSetting((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleAccordionClick = (section) => {};

  const Getbasicsetting = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: id, pageid: pageid.id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAlBasicSettings`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        const fetchedData = data.data[0];
        setBasicSetting({
          title: fetchedData.tittle,
          metaDescription: fetchedData.meta_desc,
          canonicalLink: fetchedData.canonical_tag,
        });
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getbasicsetting();
  }, []);

  return (
    <Card className="p-0">
      <Accordion.Toggle
        as={Card.Header}
        eventKey="0"
        onClick={() => handleAccordionClick("Basic Setting")}
        className="m-0 p-2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: ".2rem",
          border: "1px solid rgb(170, 215, 189)",
          background: "rgb(236, 246, 240)",
        }}
      >
        <div>Basic Setting</div>
        <div>
          <RiArrowDropDownLine style={{ fontSize: "1.5rem" }} />
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body
          className="p-2"
          style={{
            background: "#FEFAF6",
            border: "1px solid rgb(170, 215, 189)",
          }}
        >
          <Form className="p-2 mt-2" onSubmit={handleSubmit}>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={basicSetting.title}
                onChange={handleBasicSettingChange}
              />
            </Form.Group>
            <Form.Group controlId="metaDescription">
              <Form.Label>Meta Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter meta description"
                value={basicSetting.metaDescription}
                onChange={handleBasicSettingChange}
              />
            </Form.Group>
            <Form.Group controlId="canonicalLink">
              <Form.Label>Canonical Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter canonical link"
                value={basicSetting.canonicalLink}
                onChange={handleBasicSettingChange}
              />
            </Form.Group>
            <Button variant="primary" size="sm" type="submit">
              Save
            </Button>
          </Form>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
export const Projectform = ({ Getonboarddata }) => {
  const [formData, setFormData] = useState({
    site_url: "",
    site_category: "",
    comp_url1: "",
    comp_url2: "",
    comp_url3: "",
    comp_url4: "",
    comp_url5: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const { user } = useContext(GlobalContext);

  const handleSubmit = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        userid: user.user_id,
        site_url: formData.site_url,
        site_category: formData.site_category,
        comp_url1: formData.comp_url1,
        comp_url2: formData.comp_url2,
        comp_url3: formData.comp_url3,
        comp_url4: formData.comp_url4,
        comp_url5: formData.comp_url5,
        keywords: "string",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/OnboardCompany`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "1") {
        swal("Error", data.result, "error");
      } else {
        swal("Success", data.result, "success");
        setFormData({
          site_url: "",
          site_category: "",
          comp_url1: "",
          comp_url2: "",
          comp_url3: "",
          comp_url4: "",
          comp_url5: "",
        });
        Getonboarddata();
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const validate = () => {
    let valid = true;
    let errors = {};

    if (formData.site_url.trim() === "") {
      errors.site_url = "*Please enter site URL";
      valid = false;
    }

    if (formData.site_category.trim() === "") {
      errors.site_category = "*Please enter site category";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmitWithValidation = (e) => {
    e.preventDefault();
    if (validate()) {
      handleSubmit();
    }
  };

  return (
    <Container className="col-9">
      <h3 className="mt-4 mb-4 text-center">Add Project Form</h3>
      <Form onSubmit={handleSubmitWithValidation}>
        <Row className="m-0 p-0">
          <Col md={6}>
            <Form.Group controlId="formSiteUrl">
              <Form.Label>Site URL</Form.Label>
              <Form.Control
                type="text"
                name="site_url"
                value={formData.site_url}
                onChange={handleChange}
                placeholder="Enter site URL"
                isInvalid={!!errors.site_url}
              />
              <Form.Control.Feedback type="invalid">
                {errors.site_url}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formSiteCategory">
              <Form.Label>Site Category</Form.Label>
              <Form.Control
                type="text"
                name="site_category"
                value={formData.site_category}
                onChange={handleChange}
                placeholder="Enter site category"
                isInvalid={!!errors.site_category}
              />
              <Form.Control.Feedback type="invalid">
                {errors.site_category}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col md={6}>
            <Form.Group controlId="formCompUrl1">
              <Form.Label>Competitive URL 1</Form.Label>
              <Form.Control
                type="text"
                name="comp_url1"
                value={formData.comp_url1}
                onChange={handleChange}
                placeholder="Enter competitive URL 1"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formCompUrl2">
              <Form.Label>Competitive URL 2</Form.Label>
              <Form.Control
                type="text"
                name="comp_url2"
                value={formData.comp_url2}
                onChange={handleChange}
                placeholder="Enter competitive URL 2"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col md={6}>
            <Form.Group controlId="formCompUrl3">
              <Form.Label>Competitive URL 3</Form.Label>
              <Form.Control
                type="text"
                name="comp_url3"
                value={formData.comp_url3}
                onChange={handleChange}
                placeholder="Enter competitive URL 3"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formCompUrl4">
              <Form.Label>Competitive URL 4</Form.Label>
              <Form.Control
                type="text"
                name="comp_url4"
                value={formData.comp_url4}
                onChange={handleChange}
                placeholder="Enter competitive URL 4"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col md={6}>
            <Form.Group controlId="formCompUrl5">
              <Form.Label>Competitive URL 5</Form.Label>
              <Form.Control
                type="text"
                name="comp_url5"
                value={formData.comp_url5}
                onChange={handleChange}
                placeholder="Enter competitive URL 5"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col md={4} className="d-flex align-items-center">
            <Button variant="primary" size="sm" type="submit" className="mt-2">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
