import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { GlobalContext } from "../Context/GlobalState";

const Profilepage = () => {
  const { user } = useContext(GlobalContext);

  return (
    <Container className="m-md-5 m-2 card" style={{ background: "#fff" }}>
      <h1 className="mb-4">Profile Page</h1>
      {user ? (
        <>
          <div className="row p-0 m-0 mb-2">
            <p className="col" style={{ fontSize: "1rem" }}>
              Username
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {user.username}
            </p>
          </div>

          <div className="row p-0 m-0 mb-2">
            <p className="col" style={{ fontSize: "1rem" }}>
              User Type
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {user.user_type}
            </p>
          </div>

          <div className="row p-0 m-0 mb-2">
            <p className="col" style={{ fontSize: "1rem" }}>
              User ID
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {user.user_id}
            </p>
          </div>

          <div className="row p-0 m-0 mb-2">
            <p className="col" style={{ fontSize: "1rem" }}>
              Mobile
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {user.mobile}
            </p>
          </div>

          <div className="row p-0 m-0 mb-2">
            <p className="col" style={{ fontSize: "1rem" }}>
              Profile URL
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              {user.profile_url || "N/A"}
            </p>
          </div>

          <div className="row p-0 m-0 mb-2">
            <p className="col" style={{ fontSize: "1rem" }}>
              User URL
            </p>
            <p className="col" style={{ fontSize: "1rem", color: "black" }}>
              <a href={user.user_url} target="_blank" rel="noopener noreferrer">
                {user.user_url}
              </a>
            </p>
          </div>
        </>
      ) : (
        <p>No user data available.</p>
      )}
    </Container>
  );
};

export default Profilepage;
