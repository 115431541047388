import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { Link } from "react-router-dom";
import Sitemapsetting from "./Sitemapsetting";

const Pages = () => {
  const pageDisplayNames = {
    contact_us: "Contact Us",
    faq: "FAQ",
    terms_condition: "Terms & Conditions",
    cancellation_policy: "Cancellation Policy",
    refund_policy: "Refund Policy",
    New_login: "New Login",
    Generate_ID_Secret: "Generate ID Secret",
    privacy_policy: "Privacy Policy",
    Attendance_Management: "Attendance Management",
    EmployeeManagment: "Employee Management",
    Expense_Management: "Expense Management",
    Leave_Management: "Leave Management",
    Payroll_Management: "Payroll Management",
    Pricing: "Pricing",
    Home_Page:"Home Page"
  };

  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [stimapdata, setstimapdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPage, setSelectedPage] = useState(null); 
  const url = sessionStorage.getItem("selectedURLLabel");

  const Getsitemapdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ url }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllPages`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(
          data.data.map((item) => ({
            id: item.id,
            base_url: item.base_url,
            page_url: item.page_url,
            url_pages: item.url_pages,
          }))
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getsitemapdata();
  }, []);

  const filteredData = stimapdata.filter((page) => {
    const query = searchQuery.toLowerCase();
    return (
      page.page_url.toLowerCase().includes(query) ||
      page.url_pages.toLowerCase().includes(query)
    );
  });

  return (
    <div className="m-4">
      <div className="row">
        <div className="col">
          <h5>
            Pages of <u>{url}</u>
          </h5>
        </div>
        <div className="col mr-3 d-flex justify-content-end">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: "50%" }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-5 custom-scroll-hide" style={{ height: "90vh" }}>
          {filteredData.map((page) => (
            <div key={page.id} className="mb-2">
              <Card className="p-0">
                <Card.Body className="p-2 d-flex justify-content-between">
                  {pageDisplayNames[page.url_pages] || page.url_pages}
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-${page.id}`}>Click for SEO setting</Tooltip>}
                  >
                    <Link
                      onClick={() => {
                        sessionStorage.setItem("pageid", JSON.stringify(page));
                        setSelectedPage(page); // Set selected page
                      }}
                    >
                      <Button size="sm">View</Button>
                    </Link>
                  </OverlayTrigger>
                </Card.Body>
              </Card>
            </div>
          ))}
          {filteredData.length === 0 && (
            <div className="col-12 text-center mt-4">No data found</div>
          )}
        </div>
        <div className="col custom-scroll-hide" style={{ height: "90vh" }}>
          {selectedPage ? (
            <Sitemapsetting key={selectedPage.id} page={selectedPage} />
          ) : (
            <div className="text-center mt-4">No data found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pages;
