import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";

const style = {
  color: "#FFF",
};
const activeStyle = {
  color: "#FFF",
  fontWeight: "bold",
};

class Sidebar extends Component {
  state = {
    seoMenuOpen: false,
    blogsMenuOpen: false,
    user: null,
  };

  toggleMenuState(menuState) {
    this.setState((prevState) => ({
      [menuState]: !prevState[menuState],
    }));
  }

  handleMenuClick = (menuState) => {
    if (menuState !== 'seoMenuOpen' && menuState !== 'blogsMenuOpen') {
      return; // Don't toggle other menu states if not specified
    }
    this.toggleMenuState(menuState);
  };

  componentDidMount() {
    this.fetchUserInfo();
    this.onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  fetchUserInfo = () => {
    // Mock API call - replace with your actual API call
    fetch('/api/getUserInfo') // Replace with your API endpoint
      .then(response => response.json())
      .then(data => {
        this.setState({ user: data.data }); // Set user data in state
      });
  };

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/Go_seo", state: "seoMenuOpen" },
      { path: "/Go_blog", state: "blogsMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  render() {
    const { user } = this.state;

    return (
      <nav
        style={{ background: "#2F3245", minHeight: "100vh" }}
        className="sidebar sidebar-offcanvas"
        id="sidebar"
      >
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html">
            <strong style={{ color: "#FFF" }}>BUMPPY</strong>
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            <img
              src={require("../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </a>
        </div>
        <ul className="nav">
        <li
            style={{
              ...(this.isPathActive("/Go_seo/Comapny_onboard")
                ? activeStyle
                : style),
              marginTop: "2rem",
            }}
            className={
              this.isPathActive("/Go_seo/Comapny_onboard")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/Go_seo/Comapny_onboard">
              <span className="menu-icon">
                <i className="mdi mdi-translate"></i>
              </span>
              <span style={{ color: "#FFF" }} className="menu-title">
                Onboard
              </span>
            </Link>
          </li>
          <li
            style={{
              ...(this.isPathActive("/Go_seo/Project")
                ? activeStyle
                : style),
              marginTop: ".5rem",
            }}
            className={
              this.isPathActive("/Go_seo/Project")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/Go_seo/Project">
              <span className="menu-icon">
                <i className="mdi mdi-translate"></i>
              </span>
              <span style={{ color: "#FFF" }} className="menu-title">
                Add Project
              </span>
            </Link>
          </li>
          <li
            className={
              this.state.seoMenuOpen ? "nav-item menu-items active" : "nav-item menu-items"
            }
            style={{
              ...(this.state.seoMenuOpen ? activeStyle : style),
              marginTop: ".5rem",
            }}
          >
            <div
              className={
                this.state.seoMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.handleMenuClick("seoMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-cart-arrow-down"></i>
              </span>
              <span style={{ color: "#FFF" }} className="menu-title">
                <Trans>SEO</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.seoMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li
                    style={{
                      ...(this.isPathActive("/Go_seo/Dashboard")
                        ? activeStyle
                        : style),
                      marginTop: "12px",
                    }}
                    className={
                      this.isPathActive("/Go_seo/Dashboard")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className="nav-link"
                      to="/Go_seo/Dashboard"
                      onClick={(e) => {
                        if (this.isPathActive("/Go_seo/Dashboard")) {
                          e.preventDefault(); // Prevent collapsing
                        }
                      }}
                    >
                      <span className="menu-icon">
                        <i className="mdi mdi-speedometer"></i>
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        <Trans>Dashboard</Trans>
                      </span>
                    </Link>
                  </li>
                  <li
                    style={{
                      ...(this.isPathActive("/Go_seo/Seo_analyzer")
                        ? activeStyle
                        : style),
                      marginTop: "px",
                    }}
                    className={
                      this.isPathActive("/Go_seo/Seo_analyzer")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className="nav-link"
                      to="/Go_seo/Seo_analyzer"
                    >
                      <span className="menu-icon">
                        <i className="mdi mdi-translate"></i>
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        SEO Audit
                      </span>
                    </Link>
                  </li>

                  <li
                    style={
                      this.isPathActive("/Go_seo/Sitemap") ? activeStyle : style
                    }
                    className={
                      this.isPathActive("/Go_seo/Sitemap")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Sitemap">
                      <span className="menu-icon">
                        <i className="mdi mdi-speedometer"></i>
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        <Trans>Sitemap</Trans>
                      </span>
                    </Link>
                  </li>
                  <li
                    style={
                      this.isPathActive("/Go_seo/Pages") ? activeStyle : style
                    }
                    className={
                      this.isPathActive("/Go_seo/Pages")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Pages">
                      <span className="menu-icon">
                        <i className="mdi mdi-translate"></i>
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Pages
                      </span>
                    </Link>
                  </li>
                  <li
                    style={
                      this.isPathActive("/Go_seo/Social_links")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/Go_seo/Social_links")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Social_links">
                      <span className="menu-icon">
                        <i className="mdi mdi-translate"></i>
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Social Links
                      </span>
                    </Link>
                  </li>
                  <li
                    style={
                      this.isPathActive("/Go_seo/Internal_links")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/Go_seo/Internal_links")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Internal_links">
                      <span className="menu-icon">
                        <i className="mdi mdi-translate"></i>
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Internal Links
                      </span>
                    </Link>
                  </li>
                 
                  <li
                    style={
                      this.isPathActive("/Go_seo/Robot_txt")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/Go_seo/Robot_txt")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Robot_txt">
                      <span className="menu-icon">
                        <i className="mdi mdi-translate"></i>
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Robots.txt
                      </span>
                    </Link>
                  </li>
                  <li
                    style={
                      this.isPathActive("/Go_seo/Utm")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/Go_seo/Utm")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Utm">
                      <span className="menu-icon">
                        <i className="mdi mdi-translate"></i>
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                     UTM
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
        
       
       
       
         
        </ul>
        
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
