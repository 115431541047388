import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "react-quill/dist/quill.snow.css";
import { Button } from "react-bootstrap";
import swal from "sweetalert";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";

const Robottxt = () => {
  const id = sessionStorage.getItem("selectedURLId");
  const url = sessionStorage.getItem("selectedURLLabel");

  const [content, setContent] = useState(""); 
  const quillRef = useRef(null);

  useEffect(() => {
    const fetchRobotsTxt = async () => {
      try {
        console.log("Fetching robots.txt from:", url);
        const response = await fetch(`https://api.allorigins.win/get?url=${url}/robots.txt`);
        if (response.ok) {
          const data = await response.json();
          setContent(data.contents.replace(/\n/g, '<br/>'));
        } else {
          console.error("Failed to fetch robots.txt. Status:", response.status);
          setContent(`# Failed to load ${url}/robots.txt. Status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching robots.txt:", error);
        setContent(`# Error loading ${url}/robots.txt. Error: ${error.message}`);
      }
    };
    
    fetchRobotsTxt();
  }, [url]);
  

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["link", { image: "Upload Image" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["clean"],
      [{ save: "Save" }],
    ],
  };

  const handleSaveAsPDF = () => {
    const element = document.getElementById("quill-content");
    html2canvas(element).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.save("document.pdf");
    });
  };

  const handleToolbarClick = (value) => {
    if (value === "save") {
      handleSaveAsPDF();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(content),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GenerateRobottxt`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.msg, "success");
      } else {
        swal("Error", data.msg, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <div className="m-4">
        <h5 className="text-center mb-3">
          Robots.txt for <u>{url}</u>
        </h5>
        <ReactQuill
          id="quill-content"
          ref={quillRef}
          theme="snow"
          value={content}
          onChange={setContent}
          modules={modules}
          formats={[
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "color",
            "background",
            "script",
            "link",
            "image",
            "list",
            "bullet",
            "indent",
            "align",
          ]}
          toolbarHandlers={{
            save: handleToolbarClick,
          }}
        />
        <div className="mt-4">
          <Button type="submit" variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default Robottxt;
