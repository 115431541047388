

import { DOMAIN,CLIENT_CODE } from "./Client";

export const CLIENT_URL = `https://${DOMAIN}`

export const BASE_URL_AEPS = `https://bumppy.com/apibumppypay/v1/web/fingpay`;

export const BASE_URL_API_TRANXT = `https://tranxtuat.${DOMAIN}/recApiFinal`
export const BASE_URL_API_TRANXT_API = `https://tranxtuat.${DOMAIN}`

export const BASE_URL_PAYNOW = `https://apitranxt.paynnow.com`

export const BASE_URL_AGENT = `https://agent.${DOMAIN}`;

export const BASE_URL_DOC_KYC = `https://kyc.${DOMAIN}`;

export const BASE_URL_API_TRANXT_UAT_API = `https://api.goseo.in`;


// if process.env==production then "https"
export const BASE_URL_APISERVICES = `https://apiservices.${DOMAIN}`;

export const BASE_URL_API_TRANXT_SERVICE_API = `https://apiservices.${DOMAIN}`;
export const COMPANY_CODE = CLIENT_CODE;



