import React, { Component } from "react";
import { Sitemaptable } from "./Table";

const Sitemap = () => {

  return (
    <div>
     <Sitemaptable/>
    </div>
  );
};

export default Sitemap;
