import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { GlobalContext } from "../Context/GlobalState";
import swal from "sweetalert";
const socialPlatforms = [
  "Bumppy",
  "Facebook",
  "Twitter",
  "Instagram",
  "Pinterest",
  "YouTube",
  "LinkedIn",
  "Wikipedia",
  "Yelp Page",
];

const Sociallinks = () => {
  const imageMapping = {
    Bumppy:require("../../assets/images/seoimages/Blogo.png"),
    Facebook: require("../../assets/images/seoimages/facebook.png"),
    Twitter: require("../../assets/images/seoimages/twitter.png"),
    Instagram: require("../../assets/images/seoimages/insta.png"),
    Pinterest: require("../../assets/images/seoimages/Pinterest.png"),
    YouTube: require("../../assets/images/seoimages/youtube.png"),
    LinkedIn: require("../../assets/images/seoimages/linkdin.png"),
    Wikipedia: require("../../assets/images/seoimages/wiki.png"),
    "Yelp Page": require("../../assets/images/seoimages/yelp.png"),
  };

  const [formData, setFormData] = useState({
    Bumppy:"",
    Facebook: "",
    Twitter: "",
    Instagram: "",
    Pinterest: "",
    YouTube: "",
    LinkedIn: "",
    Wikipedia: "",
    YelpPage: "",
  });


  const { setTransactionLoaderState } = useContext(GlobalContext);
  const id = sessionStorage.getItem("selectedURLId");
  const url = sessionStorage.getItem("selectedURLLabel");
  const handleInputChange = (platform, event) => {
    setFormData({
      ...formData,
      [platform]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          webid: id,
          fb: formData.Facebook,
          insta: formData.Instagram,
          twiter: formData.Twitter,
          pintrest: formData.Pinterest,
          youtube: formData.YouTube,
          linkdin: formData.LinkedIn,
          wiki: formData.Wikipedia,
          yelp: formData["Yelp Page"],
        }),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/AddSocialLinks`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.result, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const Getsociallinksdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: id,  }),
      };
  
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllSocialLinks`,
        requestOptions
      );
      const data = await res.json();
  
      if (data.status === "0" && data.data.length > 0) {
        const socialLinks = data.data[0];
        setFormData({
          Facebook: socialLinks.fb_url || "",
          Twitter: socialLinks.twitter_url || "",
          Instagram: socialLinks.instagram_url || "",
          Pinterest: socialLinks.pinterest_url || "",
          YouTube: socialLinks.youtube_url || "",
          LinkedIn: socialLinks.linkdin_url || "",
          Wikipedia: socialLinks.wikipedia_url || "",
          "Yelp Page": socialLinks.yelp_url || "",
        });
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  
  useEffect(() => {
    Getsociallinksdata();
  }, []);
 

  return (
    <Container>
      <h5 className="mt-5 mb-4 text-center">Manage Social Links of <u>{url}</u></h5>
   
        <Form onSubmit={handleSubmit}>
          {socialPlatforms.map((platform) => (
            <Row key={platform} className="">
              <Col md={3} className="d-flex align-items-center">
                <Form.Label>
                  <img
                    className="card-img-top"
                    src={imageMapping[platform]}
                    alt={`${platform} logo`}
                    style={{ width: "24px", height: "24px" }}
                  />
                  &nbsp;{platform}
                </Form.Label>
              </Col>
              <Col md={7}>
                <Form.Control
                  type="text"
                  placeholder={`Enter ${platform} URL`}
                  value={formData[platform]}
                  onChange={(e) => handleInputChange(platform, e)}
                />
              </Col>
            </Row>
          ))}
       <div className="ml-3">
       <Button type="submit" variant="primary">
            Save
          </Button>
        </div>  
        </Form>
  
    </Container>
  );
};

export default Sociallinks;
