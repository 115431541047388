import ClientConfig from "./ClientConfig";

export const DOMAIN = "bumppypay.com";
// export const DOMAIN = "remittpe.com";
// export const DOMAIN = "finpe.co.in";
// export const DOMAIN = "paymin.in";
//export const DOMAIN = "lovepaisa.com";
//export const DOMAIN = "pemore.in";

const config = ClientConfig(DOMAIN);
export const CLIENT_CODE = config.CLIENT_CODE;
export const CLIENT_BASIC_AUTH = config.CLIENT_BASIC_AUTH;
export const COMPANY_RETAILER_COMPANY_ID = config.COMPANY_RETAILER_COMPANY_ID;
export const AEPS_SUPER_MERCHANT_ID = config.AEPS_SUPER_MERCHANT_ID;
export const CLIENT_PAN_NUMBER = config.CLIENT_PAN_NUMBER;


export const theme = config.theme;
