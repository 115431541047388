import React, { Component, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { Projectform } from "./Forms";

const Project = () => {
  const { setTransactionLoaderState } = useContext(GlobalContext);

  return (
    <div>
   <Projectform />
    </div>
  );
};

export default Project;
