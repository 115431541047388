import React from "react";

export default (state, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "SET_MY_ACCOUNT_INFO":
      return {
        ...state,
        myAccountInfo: action.payload,
      };
    case "SET_TRANSACTION_LOADER":
      return { ...state, transactionLoader: action.payload };
    case "SET_USER_INFO":
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
