import React, { Component, useContext, useEffect, useState } from "react";
import { Internallinksform } from "./Forms";

const Internallinks = () => {


  return (
    <div>
   <Internallinksform />
    </div>
  );
};

export default Internallinks;
