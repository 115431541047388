import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Utmtable } from "./Table";

const Utm = () => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [onboarddata, setOnboarddata] = useState([]);

  const Getonboarddata = async () => {
    setTransactionLoaderState(true);
    try {
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetUTMDetails`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ product_name: "BillPunch" }),
        }
      );
      const data = await response.json();

      if (data.status === "0") {
        setOnboarddata(data.data);
      } else {
        setOnboarddata([]);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setOnboarddata([]);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getonboarddata();
  }, []);

  return (
   <>
   <Utmtable  onboarddata={onboarddata}/>
   </>
  );
};

export default Utm;
