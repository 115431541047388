import React, { useContext } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { Tabs, Tab } from "react-bootstrap";
import { Doughnut, Bar } from "react-chartjs-2";
import { ProgressBar } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Error404 from "../error-pages/Error404";
import {useHistory} from 'react-router-dom'

const MyAccount = () => {
  const { myAccountInfo: info } = useContext(GlobalContext);
  const history = useHistory();
  console.log(info);
  const amountDueBarData = {
    labels: [
      "Day 1",
      "Day 2",
      "Day 3",
      "Day 4",
      "Day 5",
      "Day 6",
      "Day 7",
      "Day 8",
      "Day 9",
      "Day 10",
    ],
    datasets: [
      {
        label: "Profit",
        data: [39, 19, 25, 16, 31, 39, 12, 18, 33, 24],
        backgroundColor: [
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
        ],
        borderColor: [
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
          "#198ae3",
        ],
        borderWidth: 2,
        fill: true,
      },
    ],
  };

  const amountDueBarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          display: false,
          ticks: {
            beginAtZero: false,
          },
        },
      ],
      xAxes: [
        {
          display: false,
        },
      ],
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0,
      },
    },
    stepsize: 10,
  };

  const usersDoughnutChartData = {
    datasets: [
      {
        data: [80, 34, 100],
        backgroundColor: ["#44ce42", "#b66dff", "#d8d8d8"],
        borderColor: ["#44ce42", "#b66dff", "#d8d8d8"],
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ["Pink", "Blue", "Yellow"],
  };

  const usersDoughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    legend: {
      display: false,
    },
  };
  if (info == null) {
    history.push("/error-pages/error-404");
    return;
  }
  return (
    <div className="col-md-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <Tabs defaultActiveKey="Profile" id="uncontrolled-tab-example">
            <Tab
              eventKey="Profile"
              title="Profile"
              className="test-tab border-neglect"
            >
              <div className="col-md-12 grid-margin stretch-card">
                <div style={{ padding: "10px" }} className="card">
                  <h5>2 Step Verification</h5>
                  <p>
                    Add an extra layer of security to your account by using a
                    one-time verification code in addition to your password each
                    time you log in.
                  </p>
                </div>
              </div>

              <div className="col-md-12 grid-margin stretch-card">
                <div style={{ padding: "0px" }} className="card">
                  <DetailHeaderCard
                    label={`Merchant Id: ${info.vAccountNumber}`}
                    value={"Change Password"}
                  />

                  <DetailCard
                    label={"Contact Name"}
                    value={info.name ? info.name : "-"}
                  />
                  <DetailCard
                    label={"Contact Email"}
                    value={info.email ? info.email : "-"}
                  />
                  <DetailCard
                    label={"Contact Number"}
                    value={info.phoneNumber ? info.phoneNumber : "-"}
                  />
                  <DetailCard
                    label={"Business Name"}
                    value={info.businessName ? info.businessName : "-"}
                  />
                  <DetailCard
                    label={"Business Type"}
                    value={info.businessType ? info.businessType : "-"}
                  />
                  <DetailCard
                    label={"Registration Date"}
                    value={info.createdOn ? info.createdOn : "-"}
                  />
                  <DetailCard label={"Registered By"} value={"--"} />
                  <DetailCard
                    label={"Account Activated On"}
                    value={" Aug 12 2019, 01:03 pm"}
                  />
                  <DetailCard label={"Account Access"} value={"Complete"} />
                  <DetailCard
                    label={"Business Website/App details"}
                    value={"https://bumppy.com"}
                  />
                  <DetailCard
                    label={"Additional Business Website/App"}
                    value={
                      info.additionalWebsiteDetail
                        ? info.additionalWebsiteDetail
                        : "-"
                    }
                  />
                  <DetailCard
                    label={"Limit per transaction"}
                    value={
                      info.limitPerTransaction ? info.limitPerTransaction : "-"
                    }
                  />
                  {info.contactName ? (
                    <DetailCard
                      label={"Contact Name"}
                      value={info.contactName}
                    />
                  ) : null}
                </div>
              </div>

              <div className="col-md-12 grid-margin stretch-card">
                <div style={{ padding: "0px" }} className="card">
                  <DetailHeaderCard value={""} label={"Support Details"} />

                  <DetailCard
                    label={"Phone No"}
                    value={info.supportPhoneNo ? info.supportPhoneNo : "-"}
                  />
                  <DetailCard
                    label={"Email id"}
                    value={info.supportEmailId ? info.supportEmailId : "-"}
                  />
                  <DetailCard
                    label={"Website/Contact us link"}
                    value={
                      info.supportWebsiteLink ? info.supportWebsiteLink : "-"
                    }
                  />
                </div>
              </div>

              <div className="col-md-12 grid-margin stretch-card">
                <div style={{ padding: "0px" }} className="card">
                  <DetailHeaderCard value={""} label={"GST Details"} />

                  <DetailCard
                    label={"GST Details"}
                    value={info.gstDetails ? info.gstDetails : "-"}
                  />
                  <DetailCard
                    label={"Bumppy's GST Number"}
                    value={info.gstNumber ? info.gstNumber : "-"}
                  />
                </div>
              </div>

              <div className="col-md-12 grid-margin stretch-card">
                <div style={{ padding: "0px" }} className="card">
                  <DetailHeaderCard value={""} label={"Bank Account"} />

                  <DetailCard
                    label={"IFSC Code"}
                    value={info.ifscCode ? info.ifscCode : "-"}
                  />
                  <DetailCard
                    label={"Account Number"}
                    value={info.accountNumber ? info.accountNumber : "-"}
                  />
                  <DetailCard
                    label={"Beneficiary"}
                    value={"Bumppy Media Pvt Ltd"}
                  />
                </div>
              </div>

              <div className="col-md-12 grid-margin stretch-card">
                <div style={{ padding: "0px" }} className="card">
                  <DetailCard
                    label={"Login Email"}
                    value={"sumitvermacr7@gmail.com"}
                  />
                  <DetailCard
                    label={"Role"}
                    value={info.roleName ? info.roleName : "-"}
                  />
                  <DetailCard
                    label={"Beneficiary"}
                    value={"Bumppy Media Pvt Ltd"}
                  />
                </div>
              </div>

              <div className="col-md-12 grid-margin stretch-card">
                <div style={{ padding: "0px" }} className="card">
                  <DetailHeaderCard value={""} label={"Upgrade Merchant"} />

                  <div style={{ padding: "0px" }} className="card">
                    <div style={{ padding: "8px" }} className="card-body">
                      <span className="row justify-content-center">
                        You can upgrade your account to a Merchant Account by
                        giving us your business name
                      </span>

                      <div className="row justify-content-center">
                        <label
                          style={{ margin: "5px", padding: "10px" }}
                          htmlFor="exampleInputtext"
                          className="text-center"
                        >
                          Business Name:
                        </label>
                        <Form.Control
                          style={{ margin: "10px" }}
                          type="email"
                          className="col-sm-3 form-control"
                          id="exampleInputtext"
                          placeholder="Acme Inc."
                        />
                        <button
                          style={{ margin: "10px" }}
                          type="button"
                          className="btn btn-primary btn-fw"
                        >
                          Primary
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 grid-margin stretch-card">
                <div style={{ padding: "0px" }} className="card">
                  <DetailHeaderCard
                    value={"View Settlement Schedule"}
                    label={"Settlement Details"}
                  />

                  <DetailCard label={"Current Balance"} value={"₹ 0.00"} />
                </div>
              </div>

              <div className="col-md-12 grid-margin stretch-card">
                <div style={{ padding: "0px" }} className="card">
                  <DetailHeaderCard label={" Purpose Code and FIRC"} />

                  <div style={{ padding: "8px" }} className="card">
                    <div style={{ padding: "0px" }} className="card-title">
                      <span
                        style={{ padding: "0px" }}
                        className="text-muted text-center text-sm-left d-block d-sm-inline-block"
                      >
                        Purpose Code
                      </span>
                      <span
                        style={{ padding: "0px" }}
                        className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"
                      >
                        <button type="button" className="btn btn-link btn-fw ">
                          Select Code
                        </button>
                      </span>
                    </div>
                  </div>

                  <DetailCard
                    label={"FIRC Certificate"}
                    value={"Coming Soon"}
                  />
                </div>
              </div>
            </Tab>

            <Tab eventKey="Credits" title="Credits">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-7">
                          <h4
                            className="card-title font-weight-medium mb-3"
                            style={{ color: "#000" }}
                          >
                            Amount Credit
                          </h4>
                          <h1
                            style={{ color: "#000" }}
                            className="font-weight-medium mb-0"
                          >
                            ₹ 69,848.55
                          </h1>

                          <p className="mb-0" style={{ color: "#000" }}>
                            Transactions worth amount credits will be free of
                            any transaction fee.
                          </p>
                        </div>
                        <div className="col-md-5 d-flex align-items-end mt-4 mt-md-0">
                          <Bar
                            data={amountDueBarData}
                            options={amountDueBarOptions}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-5 d-flex align-items-center">
                          <Doughnut
                            data={usersDoughnutChartData}
                            options={usersDoughnutChartOptions}
                          />
                        </div>
                        <div className="col-md-7">
                          <h4
                            className="card-title font-weight-medium mb-0 d-none d-md-block"
                            style={{ color: "#000" }}
                          >
                            Active Users
                          </h4>
                          <div className="wrapper mt-4">
                            <div className="d-flex justify-content-between mb-2">
                              <div className="d-flex align-items-center">
                                <p
                                  className="mb-0 font-weight-medium"
                                  style={{ color: "#000" }}
                                >
                                  67,550
                                </p>
                                <small
                                  style={{ color: "#000" }}
                                  className="text-muted ml-2"
                                >
                                  Email account
                                </small>
                              </div>
                              <p className="mb-0 font-weight-medium">80%</p>
                            </div>
                            <ProgressBar variant="success" now={80} />
                          </div>
                          <div className="wrapper mt-4">
                            <div className="d-flex justify-content-between mb-2">
                              <div className="d-flex align-items-center">
                                <p
                                  className="mb-0 font-weight-medium"
                                  style={{ color: "#000" }}
                                >
                                  21,435
                                </p>
                                <small
                                  style={{ color: "#000" }}
                                  className="text-muted ml-2"
                                >
                                  Requests
                                </small>
                              </div>
                              <p
                                style={{ color: "#000" }}
                                className="mb-0 font-weight-medium"
                              >
                                34%
                              </p>
                            </div>
                            <ProgressBar variant="primary" now={34} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-7">
                          <h4
                            className="card-title font-weight-medium mb-3"
                            style={{ color: "#000" }}
                          >
                            Commission Credit
                          </h4>
                          <h1
                            style={{ color: "#000" }}
                            className="font-weight-medium mb-0"
                          >
                            ₹ 0.00
                          </h1>
                          <p className="mb-0" style={{ color: "#000" }}>
                            Get your transactions settled in full. Transaction
                            charges will be deducted from Commission credits.
                          </p>
                        </div>

                        <div className="col-md-5 d-flex align-items-end mt-4 mt-md-0">
                          <Bar
                            data={amountDueBarData}
                            options={amountDueBarOptions}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-7">
                          <h4
                            className="card-title font-weight-medium mb-3"
                            style={{ color: "#000" }}
                          >
                            Refund Credit
                          </h4>
                          <h1
                            style={{ color: "#000" }}
                            className="font-weight-medium mb-0"
                          >
                            ₹ 0.00
                          </h1>
                          <p className="mb-0" style={{ color: "#000" }}>
                            Do not want to refund from your settled amounts? Use
                            refund credits.
                          </p>
                        </div>

                        <div className="col-md-5 d-flex align-items-end mt-4 mt-md-0">
                          <Bar
                            data={amountDueBarData}
                            options={amountDueBarOptions}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Balances" title="Balances">
              <div className="row">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                  Note: Standard TDR charges applies on adding funds
                </span>
                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                  <button type="button" className="btn btn-link btn-fw ">
                    Learn more about Balances
                  </button>
                </span>
              </div>
              <BalanceCard
                balanceType="AEPS Balance"
                balance={
                  typeof info.aepsBalance != "undefined"
                    ? info.aepsBalance
                    : "-"
                }
                info="Add funds to your reserve balance to increase the
                  negative balance limit. Thinking of withdrawing
                  your reserve balance?"
                buttonName="Activate"
              />
              <BalanceCard
                balanceType="DMT Balance"
                balance={
                  typeof info.dmtBalance != "undefined" ? info.dmtBalance : "-"
                }
                info="Add funds to your reserve balance to increase the
                  negative balance limit. Thinking of withdrawing
                  your reserve balance?"
                buttonName="Activate"
              />
              <BalanceCard
                balanceType="Current Wallet Balance"
                balance={
                  typeof info.virtualBalance != "undefined"
                    ? info.virtualBalance
                    : "-"
                }
                info="Add funds to your account to process
                            refunds/transfers when the account balance goes low.
                            Adding large funds to your account?"
                buttonName="Add Funds"
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
const DetailCard = ({ label, value }) => {
  return (
    <div style={{ borderRadius: "0px", padding: "8px" }} className="card">
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span>{label}</span>
        </div>
        <div className="col" style={{ textAlign: "right" }}>
          <span> {value}</span>
        </div>
      </div>
    </div>
  );
};

const DetailHeaderCard = ({ label, value }) => {
  return (
    <div
      style={{
        borderWidth: "0px",
        background: "#e7eaf6",
        borderRadius: "0px",
        padding: "8px",
      }}
      className="card"
    >
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span>{label}</span>
        </div>
        <div className="col" style={{ textAlign: "right" }}>
          <span> {value}</span>
        </div>
      </div>
    </div>
  );
};

const BalanceCard = ({ balanceType, balance, info, buttonName }) => {
  return (
    <div className="row">
      <div className="col-sm-6 col-md-12 grid-margin stretch-card">
        <div style={{ padding: "0px" }} className="card">
          <div className="card-body">
            <div className="col">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                <h5
                  style={{ color: "#000" }}
                  className="card-title font-weight-medium mb-3"
                >
                  {balanceType}
                </h5>
              </span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                <button
                  style={{
                    color: "#528ff0",
                    border: "1px solid #528ff0",
                  }}
                  className="btn btn-outline-light btn-link btn-fw"
                  type="button"
                >
                  {buttonName}
                </button>
              </span>
              <h1 style={{ color: "#000" }} className="font-weight-medium mb-0">
                {balance}
              </h1>

              <p style={{ color: "#000" }} className="mb-0">
                {info}
                <button
                  style={{ color: "#528ff0" }}
                  className="btn btn-outline-light btn-link btn-fw"
                  type="button"
                >
                  Contact Us
                </button>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
