import React from "react";
import { Accordion } from "react-bootstrap";
import { Basicsetting, Header, Keyword, Ogsetting, Paragraph } from "./Forms";
const Sitemapsetting = () => {
  const id = sessionStorage.getItem("selectedURLId");

  const pageid = JSON.parse(sessionStorage.getItem("pageid"));

  return (
    <div>
      <h6 className="mx-3">Seo Setting of {pageid.url_pages}</h6>
      <Accordion className="mx-3" defaultActiveKey="">
        <Basicsetting />
        <Ogsetting />

        <Header />

        <Paragraph />

        <Keyword />
      </Accordion>
    </div>
  );
};

export default Sitemapsetting;
