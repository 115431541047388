import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";
const user = JSON.parse(sessionStorage.getItem("user"));
const initialState = {
  myAccountInfo: null,
  token: null,
  transactionLoader: false,
  user: user,
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
 
  function setToken(token) {
    dispatch({
      type: "SET_TOKEN",
      payload: token,
    });
  }

  function setMyAccountInfo(info) {
    console.log(info);
    dispatch({
      type: "SET_MY_ACCOUNT_INFO",
      payload: info,
    });
  }

  function setTransactionLoaderState(loaderState) {
    dispatch({
      type: "SET_TRANSACTION_LOADER",
      payload: loaderState,
    });
  }
  function setUserInfo(info) {
    sessionStorage.setItem("user", JSON.stringify(info));
    dispatch({
      type: "SET_USER_INFO",
      payload: info,
    });
  }
  return (
    <GlobalContext.Provider
      value={{
        token: state.token,
        myAccountInfo: state.myAccountInfo,
        transactionLoader : state.transactionLoader,
        user: state.user, 
        setToken,
        setMyAccountInfo,
        setTransactionLoaderState,
        setUserInfo
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
