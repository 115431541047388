import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { DataTables } from "../tables/DataTables";
import { BasicElements } from "../form-elements/BasicElements";

export class transaction extends Component {
  render() {
    return (
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <Tabs defaultActiveKey="Payments" id="uncontrolled-tab-example">
              <Tab eventKey="Payments" title="Payments" className="test-tab">
                <BasicElements />
                <DataTables />
              </Tab>

              <Tab eventKey="Refunds" title="Refunds">
                <BasicElements />
                <DataTables />
              </Tab>

              <Tab eventKey="Batch Refunds" title="Batch Refunds">
                <BasicElements />
                <DataTables />
              </Tab>

              <Tab eventKey="Orders" title="Orders">
                <BasicElements />
                <DataTables />
              </Tab>

              <Tab eventKey="Disputes" title="Disputes">
                <BasicElements />
                <DataTables />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default transaction;
