import React from "react";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";

const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2.0.2/countries-110m.json";

const Map = ({ data }) => {
  return (
    <ComposableMap>
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              style={{
                default: {
                  fill: "#D3D3D3", 
                  outline: "none",
                },
                hover: {
                  fill: "#FFD700", 
                  outline: "none",
                },
                pressed: {
                  fill: "#FFD700", 
                  outline: "none",
                },
              }}
            />
          ))
        }
      </Geographies>
      {data.map((point, index) => (
        <Marker key={index} coordinates={[point.lng, point.lat]}>
          <circle r={5} fill="#FFD700" stroke="#333" strokeWidth={1} /> 
          {/* <text 
            textAnchor="middle"
            style={{ fontFamily: "system-ui", fill: "#333", fontSize: 12 }}
          >

            {point.name}
          </text>*/}
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default Map;
