import React, { useContext } from "react";
import Select from "react-select";
import { Button, Card, Modal, Spinner } from "react-bootstrap";
import ReactJson from "react-json-view";
import { GlobalContext } from "../Context/GlobalState";
export const Projectmodal = ({
  modalIsOpen,
  handleClose,
  urlOptions,
  selectedURL,
  handleSelectChange,
}) => {


  
  return (
    <>
      <div>
        <Modal show={modalIsOpen} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Select Project URL</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span style={{ color: "#0F67B1" }}>Select Project :</span>
              <Select
                options={urlOptions}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select URL"
                value={selectedURL}
                onChange={handleSelectChange}
                menuPlacement="auto"
                menuPosition="fixed"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#fff",
                    borderColor: "#ccc",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: "#fff",
                    zIndex: 1050,
                  }),
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export const Jsonmodal = ({
    showModal,
    handleCloseModal,
    loadingModal,
    selectedURL,
    jsonData,validateJson,handleSubmit
  }) => {
    const { setTransactionLoaderState } = useContext(GlobalContext);
    return (
      <>
        <div>
        <Modal show={showModal} onHide={handleCloseModal} className="jsonmodal">
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedURL ? `JSON for ${selectedURL.label}` : "JSON Data"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ height: "70vh", overflow: "auto" }}>
              {loadingModal ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <ReactJson src={jsonData} />
              )}
            </div>
            <Button
              className="mt-4 mr-2"
              variant="info"
              size="sm"
              onClick={validateJson}
            >
              Validate JOSN
            </Button>

            <Button
              className="mt-4"
              variant="info"
              size="sm"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Modal.Body>
        </Modal>
        </div>
      </>
    );
  };