import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button,Dropdown } from "react-bootstrap";

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};

class Sidebarnew extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar2").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });
  }

  render() {
    var id,
      AccountDescription,
      CreatedAt,
      basePrice,
      status,
      AccountNumber,
      BenificiaryName,
      IfscCode,
      UpiId,
      AmmountPaid,
      CustomerId,
      CloseBy,
      Notes,
      mobileNo,
      createdBy,
      panNo,
      adhaarNo,
      beneAccountNo,
      beneAccountIfsc,
      beneFullName,
      customerCode,
      transferAmt,
      transferCcy,
      transferTimestamp,
      transferType,
      transferUniqueNo,
      userId,
      validateId;
    if (typeof this.props.details !== "undefined") {
      var details = this.props.details;
      id = details.vId;
      AccountDescription = details.AccountDescription;
      CreatedAt = details.createdOn;
      basePrice = details.basePrice;
      status = details.status;
      AccountNumber = details.accountNo;
      BenificiaryName = details.BenificiaryName;
      IfscCode = details.ifscCode;
      UpiId = details.UpiId;
      AmmountPaid = details.AmmountPaid;
      CustomerId = details.CustomerId;
      CloseBy = details.CloseBy;
      Notes = details.Notes;
      mobileNo = details.mobileNo;
      createdBy = details.createdBy;
      panNo = details.panNo;
      adhaarNo = details.aadharNo;
      beneAccountIfsc = details.beneAccountIfsc;
      beneAccountNo = details.beneAccountNo;
      beneFullName = details.beneFullName;
      customerCode = details.customerCode;
      transferAmt = details.transferAmt;
      transferCcy = details.transferCcy;
      transferTimestamp = details.transferTimestamp;
      transferType = details.transferType;
      transferUniqueNo = details.transferUniqueNo;
      userId = details.userId;
      validateId = details.validateId;
    }
    console.log(this.props);
    return (
      <nav
        className="side-sheet sidebar Sidebar23"
        id="sidebar2"
        style={Sidebar22}
      >
        <div style={{ padding: "0px",marginLeft:'10px',marginRight:'10px' }} className="row">
          <div style={{padding:'6px'}} className="col">
            <span style={{ marginLeft: "4px" }}>
              <i className="fa fa-bank"></i>
            </span>
            <span> {id ? id : ""}</span>
          </div>
          <div style={{paddingRight:'0px'}} className="col">
            <span
              style={{
                fontSize: "2rem",
                display: "flex",
                color: "#b2b2b3",
                justifyContent: "end",
                ariaHidden: "true",
                paddingRight:'4px',
                cursor:'pointer'
              }}
              onClick={() => {
                document
                  .querySelector(".content-wrapper")
                  .classList.toggle("sidebar-icon-only");
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <Dropdown.Divider />

        <div className="col grid-margin stretch-card">
          <div style={{ padding: "0px" }} className="card">
            <DetailHeaderCard label="Account Details" value="Copy Details" />

            {AccountNumber ? (
              <BorderLessCard label="Account Number" value={AccountNumber} />
            ) : null}
            {BenificiaryName ? (
              <BorderLessCard
                label="Beneficiary Name"
                value={BenificiaryName}
              />
            ) : null}
            {IfscCode ? (
              <BorderLessCard label="IFSC Code" value={IfscCode} />
            ) : null}
            {UpiId ? (
              <BorderLessCard label="UPI Address" value={UpiId} />
            ) : null}
          </div>
        </div>

        <div className="col">
          <div className="preview-list">
            {AmmountPaid ? (
              <BorderLessCard label="Amount Paid" value={AmmountPaid} />
            ) : null}
            {status ? (
              <BorderLessCard
                label="Status"
                value="1 Hour ago"
                badge={
                  <div
                    style={{
                      fontSize: "0.8rem",
                      padding: "5px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                    className={
                      status == "Closed"
                        ? "badge badge-success badge-pill"
                        : "badge badge-warning badge-pill"
                    }
                  >
                    {status}
                  </div>
                }
              />
            ) : null}

            {adhaarNo ? (
              <BorderLessCard label="Adhaar Number" value={adhaarNo} />
            ) : null}
            {CustomerId ? (
              <BorderLessCard label="Customer Id" value={CustomerId} />
            ) : null}
            {createdBy ? (
              <BorderLessCard label="Created By" value={createdBy} />
            ) : null}
            {CreatedAt ? (
              <BorderLessCard
                label="Created At"
                value="14 Sep 2021, 04:41:40 pm"
              />
            ) : null}
            {CloseBy ? (
              <BorderLessCard label="Close By" value={CloseBy} />
            ) : null}
            {mobileNo ? (
              <BorderLessCard label="Mobile Number" value={mobileNo} />
            ) : null}
            {panNo ? <BorderLessCard label="Pan Number" value={panNo} /> : null}
            {/* payments */}
            {beneAccountNo ? (
              <BorderLessCard
                label="Beneficiary Account Number"
                value={beneAccountNo}
              />
            ) : null}
            {beneAccountIfsc ? (
              <BorderLessCard
                label="Beneficiary IFSC Code"
                value={beneAccountIfsc}
              />
            ) : null}
            {beneFullName ? (
              <BorderLessCard
                label="Beneficiary Full Name"
                value={beneFullName}
              />
            ) : null}
            {transferAmt ? (
              <BorderLessCard label="Transfer Amount" value={transferAmt} />
            ) : null}
            {transferType ? (
              <BorderLessCard label="Transfer Type" value={transferType} />
            ) : null}
            {transferUniqueNo ? (
              <BorderLessCard label="Transfer Id" value={transferUniqueNo} />
            ) : null}
            {transferTimestamp ? (
              <BorderLessCard
                label="Transfer TimeStamp"
                value={transferTimestamp}
              />
            ) : null}
          </div>
        </div>

        <div className="card-body">
          <div className="template-demo">
            <Button variant="outline-primary">Close Account</Button>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className="card-title">Payments to this account - 0 payments</p>
          </div>
          <div className="col">
            <button type="button" className="btn btn-link ">
              View Account Details
            </button>
          </div>
        </div>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector(".content-wrapper");
    document.querySelectorAll(".sidebar2 .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

const DetailCard = ({ label, value, labelColor, valueColor }) => {
  return (
    <div style={{ borderRadius: "0px", padding: "8px" }} className="card">
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span style={{ color: labelColor }}>{label}</span>
        </div>
        <div className="col">
          <span style={{ color: valueColor }}> {value}</span>
        </div>
      </div>
    </div>
  );
};

const BorderLessCard = ({ label, value, badge }) => {
  return (
    <div style={{ borderRadius: "0px", padding: "8px" }}>
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span style={{ color: "#8991ae" }}>{label}</span>
        </div>
        <div className="col">{badge ? badge : <span> {value}</span>}</div>
      </div>
    </div>
  );
};

const DetailHeaderCard = ({ label, value }) => {
  return (
    <div
      style={{
        borderWidth: "0px",
        background: "#e7eaf6",
        borderRadius: "0px",
        padding: "8px",
      }}
      className="card"
    >
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span>{label}</span>
        </div>
        <div className="col">
          <span> {value}</span>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Sidebarnew);
