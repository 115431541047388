import React, { Component, useContext, useEffect, useState } from "react";

import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { Oboardform } from "./Forms";
import { Onboardtable } from "./Table";

const Comapnynboard = () => {
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [onboarddata, setonboarddata] = useState([]);
  const Getonboarddata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllOnboardedCompany`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setonboarddata(
          data.data.map((item, index) => ({
            id: `${index + 1}`,
            companyName: item.company_name,
            companyDomain: item.company_domain,
            regNo: item.reg_no,
            gst: item.gst,
            companyPan: item.company_pan,
            ownerName: item.owner_founder,
            ownerMobile: item.owner_mobile,
            status: "Open",
            action: "",
          }))
        );
      } else {
        setonboarddata([]);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
  
    }
  };

  useEffect(() => {
    Getonboarddata("");
  }, []);
  return (
    <div>
   <Oboardform Getonboarddata={Getonboarddata}/>
   <Onboardtable  onboarddata={onboarddata}/>
    </div>
  );
};

export default Comapnynboard;
