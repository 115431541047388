import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter,Switch, Route, Redirect } from "react-router-dom";
import App from "./app/App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import "./custom.scss";
import { GlobalProvider } from "./app/Context/GlobalState";
import Documentation from './app/documentaion/Documentation'
ReactDOM.render(
  <BrowserRouter>
    <GlobalProvider>
      <Switch >
      <Route  path="/docs" component={Documentation} />
      <Route  path="/" component={App} />
      </Switch>
    </GlobalProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
