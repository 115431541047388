import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import Select from "react-select";
export const Sitemaptable = () => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };
  const rowStyle = (row, index) => {
    return index % 2 === 0 ? greyStyle : whiteStyle;
  };

  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [stimapdata, setstimapdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const url = sessionStorage.getItem("selectedURLLabel");
  const id = sessionStorage.getItem("selectedURLId");

  const Getsitemapdata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ company_id: id, url: url }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllsitemaps`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(
          data.data.map((item, index) => ({
            id: `${index + 1}`,
            url: item.page_url,
            priority: item.priority,
            frequency: item.frequency,
            lastModified: item.last_modified,
            status: "Open",
            action: "",
          }))
        );
      } else if (data.status === "1") {
        swal({
          title: "Data Fetch Confirmation",
          text: "Do you want to fetch Sitemap?",
          icon: "info",
          buttons: ["Cancel", "Fetch"],
          dangerMode: false,
        }).then((fetchConfirmed) => {
          if (fetchConfirmed) {
            Fetchsitemapdata(url, id);
          } else {
            swal("Info", "Data fetch cancelled.", "info");
          }
        });
      } else {
        setstimapdata([]);
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
      swal("Error", "Failed to fetch data. Please try again later.", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const Fetchsitemapdata = async (url, id) => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ company_id: id, url: url }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/FetchAllExtractSitemaps`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(data.data);
      } else {
        setstimapdata([]);
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
      swal("Error", "Failed to fetch data. Please try again later.", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Order #",
      sort: true,
    },
    {
      dataField: "url",
      text: "URL",
      sort: true,
    },
    {
      dataField: "priority",
      text: "Priority",
      sort: true,
    },
    {
      dataField: "frequency",
      text: "Frequency",
      sort: true,
    },
    {
      dataField: "lastModified",
      text: "Last Modified",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: () => {
        return (
          <div>
            <button
              style={{
                background: "#FFF",
                color: "#007cb9",
                border: "1px #007cb9 solid",
              }}
              className="btn"
            >
              <Link to="/Go_seo/Addsitemap">
                <MdEdit /> Edit
              </Link>
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  useEffect(() => {
    Getsitemapdata();
  }, []);

  // Filter data based on search query
  const filteredData = stimapdata.filter((item) => {
    const query = searchQuery.toLowerCase();
    return (
      item.url.toLowerCase().includes(query) ||
      item.priority.toLowerCase().includes(query) ||
      item.frequency.toLowerCase().includes(query) ||
      item.lastModified.toLowerCase().includes(query)
    );
  });

  return (
    <div>
      <div className="row ">
        <div className="col-12">
          <ToolkitProvider
            keyField="id"
            bootstrap4
            data={filteredData} // Use filtered data here
            columns={columns}
            search
          >
            {({ baseProps }) => (
              <div>
                <div className="row mt-2 mb-2">
                  <h5 className="col text">
                    Sitemap of <u>{url}</u>
                  </h5>
                  <div className="col d-flex justify-content-end">
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{ maxWidth: "300px", marginRight: "10px" }}
                    />
                    <Link to="/Go_seo/Addsitemap">
                      <Button className="ml-2" size="sm">
                        Add Sitemap
                      </Button>
                    </Link>
                    <Link >   <Button  className="ml-2" size="sm" onClick={Getsitemapdata}>
                      Fetch Sitemap
                    </Button>  </Link>
                  </div>
                </div>

                <BootstrapTable
                  rowStyle={rowStyle}
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                  {...baseProps}
                  wrapperClasses="table-responsive"
                />
                {filteredData.length === 0 && (
                  <div className="text-center mt-4">No data found</div>
                )}
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </div>
  );
};

export const Pagetable = () => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };
  const rowStyle = (row, index) => {
    if (index % 2 === 0) {
      return greyStyle;
    } else {
      return whiteStyle;
    }
  };
  const { user } = useContext(GlobalContext);

  const [urlOptions, setUrlOptions] = useState([]);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [stimapdata, setstimapdata] = useState([]);
  const [searchURL, setSearchURL] = useState("");
  const [selectedId, setSelectedId] = useState(null);

  const Getsitemapdata = async (url) => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ url }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllPages`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setstimapdata(
          data.data.map((item) => ({
            id: item.id,
            base_url: item.base_url,
            page_url: item.page_url,
            url_pages: item.url_pages,
          }))
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const Getsitemapurl = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userid: user.user_id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllSitesUrl`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const options = data.data.map((item) => ({
          value: item.site_url,
          label: item.site_url,
          id: item.id,
        }));
        setUrlOptions(options);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleSearch = () => {
    if (searchURL.trim() !== "") {
      Getsitemapdata(searchURL);
    } else {
      swal("Warning", "Please enter a valid URL.", "warning");
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "base_url",
      text: "Base URL",
      sort: true,
    },
    {
      dataField: "page_url",
      text: "Page URL",
      sort: true,
    },
    {
      dataField: "url_pages",
      text: "URL Pages",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: () => {
        return (
          <div>
            <button
              style={{
                background: "#FFF",
                color: "#007cb9",
                border: "1px #007cb9 solid",
              }}
              className="btn"
            >
              <Link to="/Go_seo/Sitemapsetting">
                <i className="mdi mdi-eye-outline text-primary"></i> View
              </Link>
            </button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  useEffect(() => {
    Getsitemapurl("");
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <ToolkitProvider
            keyField="id"
            bootstrap4
            data={stimapdata}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <div className="m-4 d-flex align-items-center justify-content-center">
                  <p className="mb-2 mr-2">Select URL:</p>
                  <Select
                    options={urlOptions}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select URL"
                    value={urlOptions.find(
                      (option) => option.value === searchURL
                    )}
                    onChange={(selectedOption) => {
                      setSearchURL(selectedOption.value);
                      setSelectedId(selectedOption.id);
                    }}
                    styles={{
                      container: (provided) => ({ ...provided, width: "25%" }),
                    }}
                  />
                  <Button className="ml-2" size="sm" onClick={handleSearch}>
                    Search
                  </Button>
                </div>
                <BootstrapTable
                  rowStyle={rowStyle}
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                  {...props.baseProps}
                  wrapperClasses="table-responsive"
                />
                {stimapdata.length === 0 ? (
                  <div className="text-center mt-4">No data found</div>
                ) : null}
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </div>
  );
};
export const Onboardtable = ({ onboarddata }) => {
  const whiteStyle = { background: "#FFF" };
  const greyStyle = { background: "#e7eaf6" };
  const rowStyle = (row, index) => {
    if (index % 2 === 0) {
      return greyStyle;
    } else {
      return whiteStyle;
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Order #",
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
    },
    {
      dataField: "companyDomain",
      text: "Company Domain",
      sort: true,
    },
    {
      dataField: "regNo",
      text: "Registration Number",
      sort: true,
    },
    {
      dataField: "gst",
      text: "GST",
      sort: true,
    },
    {
      dataField: "companyPan",
      text: "Company PAN",
      sort: true,
    },
    {
      dataField: "ownerName",
      text: "Owner Name",
      sort: true,
    },
    {
      dataField: "ownerMobile",
      text: "Owner Mobile",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      <div className="row d-flex justify-content-center">
        <div className="col-11 ">
          <ToolkitProvider
            keyField="id"
            bootstrap4
            data={onboarddata}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <BootstrapTable
                  rowStyle={rowStyle}
                  defaultSorted={defaultSorted}
                  pagination={paginationFactory()}
                  {...props.baseProps}
                  wrapperClasses="table-responsive"
                />
                {onboarddata.length === 0 ? (
                  <div className="text-center mt-4">No data found</div>
                ) : null}
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </div>
  );
};

export const Utmtable = ({onboarddata}) => {
 

  return (
    <div className="m-3 mt-4">
      <h2>UTM Details</h2>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Page URL</th>
              <th>UTM Source</th>
              <th>UTM Medium</th>
              <th>UTM Campaign</th>
              <th>UTM Term</th>
              <th>UTM Content</th>
              <th>Referrer</th>
              <th>User Agent</th>
              <th>UTM Timestamp</th>
              <th>Product Name</th>
            </tr>
          </thead>
          <tbody>
            {onboarddata.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td><a href={item.page_url} target="_blank" rel="noopener noreferrer">{item.page_url}</a></td>
                <td>{item.utm_source || 'N/A'}</td>
                <td>{item.utm_medium || 'N/A'}</td>
                <td>{item.utm_campaign || 'N/A'}</td>
                <td>{item.utm_term || 'N/A'}</td>
                <td>{item.utm_content || 'N/A'}</td>
                <td>{item.referrer || 'N/A'}</td>
                <td>{item.user_agent || 'N/A'}</td>
                <td>{item.utm_timestamp || 'N/A'}</td>
                <td>{item.product_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

