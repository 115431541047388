import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import Seoanalyzeres from "./Seoanalyzers";

function Seoanalyzer() {
  const url = sessionStorage.getItem("selectedURLLabel");
  const [URL, setURL] = useState(url);
  const [showResults, setShowResults] = useState(false);

  const handleChange = (event) => {
    setURL(event.target.value);
  };
 
console.log(url);
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted URL:", URL);
    setShowResults(true);
  };

  const handleGoBack = () => {
    setShowResults(false);
    setURL(""); 
  };

  return (
    <div className="m-3 mt-4">
     
      {!showResults ? (
        <div className="d-flex align-items-center justify-content-center" style={{ height: '50vh' }}>
          <div className="text-center">
            <h1 className="mb-5">SEO Inspector</h1>
            <h4 className="mb-5">
              Ensure every page is perfectly optimized and get actionable insights to<br />
              boost your online presence.
            </h4>
            <form
              onSubmit={handleSubmit}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="form-group mb-0 mr-2" style={{ width: "20rem" }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Website URL e.g. https://google.com"
                  value={URL}
                  onChange={handleChange}
                />
              </div>
              <button type="submit" className="btn btn-success">
                Inspect
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div>
            <div className="row">
                <div className="col">
                    <h5 className="">Your SEO audit report is ready!</h5>
                </div>
            <div className="col justify-content-end d-flex ">
            <Button  size="sm"
              className="btn btn-primary"
              onClick={handleGoBack}
            >
              Go Back and Analyze
            </Button>
          </div></div>
          <Seoanalyzeres url={URL} />

   
          
        </div>
      )}
    </div>
  );
}

  
  
export default Seoanalyzer;
