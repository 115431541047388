import React, { useContext, useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { GlobalContext } from "../Context/GlobalState";

const LineChart = () => {
  const [graphData, setGraphData] = useState({
    labels: ["Last 30 Minutes", "Last 5 Minutes"],
    datasets: [
      {
        label: "Active Users",
        data: [0, 0],
        borderColor: "yellow",
        backgroundColor: "rgba(255, 255, 0, 0.2)",
        fill: true,
      },
      
    ],
  });

  const options = {
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ": " + tooltipItem.raw;
          },
        },
      },
    },
  };

  const { setTransactionLoaderState } = useContext(GlobalContext);

  const getGraphData = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ product_name: "BillPunch" }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetUserActiveStatus`,
        requestOptions
      );
      const result = await res.json();

      if (result.status === "0") {
        const activeUsers = result.data[0];
        setGraphData({
          labels: ["Last 30 Minutes", "Last 5 Minutes"],
          datasets: [
            {
              label: "Active Users",
              data: [activeUsers.users_last_30min, activeUsers.users_last_5min],
              borderColor: "#FFCE56",
              backgroundColor: "rgba(255, 255, 0, 0.2)",
              fill: true,
            },
          ],
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getGraphData();
  }, []); 

  return (
    <div>
      <div className="row">
        <div className="col">
          <p
            className="m-0 p-0"
            style={{
              margin: 0,
              padding: 0,
              textDecoration: "underline",
              textDecorationStyle: "dotted",
            }}
          >
            Active users in last 30 minutes
          </p>
          <h6>{graphData.datasets[0].data[0]}</h6>
        </div>
        <div
          className="col"
          style={{
            margin: 0,
            padding: 0,
            textDecoration: "underline",
            textDecorationStyle: "dotted",
          }}
        >
          {" "}
          <p
            className="m-0 p-0"
            style={{
              margin: 0,
              padding: 0,
              textDecoration: "underline",
              textDecorationStyle: "dotted",
            }}
          >
            Active users in last 5 minutes
          </p>
          <h6>{graphData.datasets[0].data[1]}</h6>
        </div>
      </div>
      <Line data={graphData} options={options} />
    </div>
  );
};

export default LineChart;
